import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PATHS } from '../utils/constants';
import ChatPage from '../pages/ChatPage';
import TeachersPage from '../pages/TeachersPage';
import ReviewPage from '../pages/ReviewPage';
import LessonsPage from '../pages/LessonsPage';
import ProfilePage from '../pages/ProfilePage';
import RegistrationPage from '../pages/RegistrationPage';
import { useUserContext } from '../contexts/UserContext';
import RolePlayPage from '../pages/RolePlayPage';
import SubscriptionPage from '../pages/SubscriptionPage';
import useAppUpdates from '../hooks/useAppUpdates';
import Layout from './Layout';
import ProtectedRoutesWrapper from './ProtectedRoutesWrapper';
import Tutorial from './Tutorial';
import ScrollToTop from './ScrollToTop';
import { Toaster } from 'react-hot-toast';

const App = () => {
  const navigate = useNavigate();
  const { user } = useUserContext();
  useAppUpdates();

  useEffect(() => {
    if (user?.isNewUser) navigate(PATHS.REGISTRATION, { replace: true });
  }, [navigate, user?.isNewUser]);

  return (
    <Tutorial>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path={PATHS.REGISTRATION} element={<RegistrationPage />} />
          <Route path={PATHS.SUBSCRIPTION} element={<SubscriptionPage />} />

          {/* Protected routes */}
          <Route element={<ProtectedRoutesWrapper />}>
            <Route path={PATHS.REVIEW} element={<ReviewPage />} />
            <Route path={PATHS.CHAT} element={<ChatPage />} />
            <Route path={PATHS.LESSONS} element={<LessonsPage />} />
            <Route path={PATHS.TEACHERS} element={<TeachersPage />} />
            <Route path={PATHS.PROFILE} element={<ProfilePage />} />
            <Route path={PATHS.ROLE_PLAY} element={<RolePlayPage />} />
          </Route>

          <Route path='*' element={<Navigate to={PATHS.CHAT} replace />} />
        </Routes>
        <Toaster />
      </Layout>
    </Tutorial>
  );
};

export default App;
