import apiClient from '.';

const fetchConfig = async () => {
  const { data } = await apiClient.get('/api/config');

  return data;
};

const configAPI = {
  fetchConfig,
};

export default configAPI;
