import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EVENTS, PATHS } from '../utils/constants';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import { useWidgetContext } from '../contexts/WidgetContext';
import CustomBottomNav from './CustomBottomNav';
import NavSlider from './NavSlider';
import FeedbackTakeover from './FeedbackTakeover';

const FEEDBACK_TAKEOVER = 'feedback-takeover';

const Layout = ({ children }: PropsWithChildren) => {
  const { isNavSliderOpen } = useWidgetContext();
  const { pathname } = useLocation();
  const [showFeedbackTakeover, setShowFeedbackTakeover] = useState(false);
  const isTabletOrLarger = useIsTabletOrLarger();

  const shouldShowBottomNav = [
    PATHS.PROFILE,
    PATHS.REVIEW,
    PATHS.ROLE_PLAY,
    PATHS.LESSONS,
    PATHS.TEACHERS,
  ].includes(pathname);

  /**
   * Handle feedback takeover blur
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === FEEDBACK_TAKEOVER) {
        setShowFeedbackTakeover(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger]);

  return (
    //note: removed overflow-hidden for changes to login page (used as landing). If causes issues, consider cond applying here based on path, exclude /login
    <div className='h-screen w-screen relative'>
      {isNavSliderOpen && <NavSlider {...{ setShowFeedbackTakeover }} />}
      {children}
      {showFeedbackTakeover && (
        <FeedbackTakeover
          {...{
            id: FEEDBACK_TAKEOVER,
            closeFeedbackTakeover: () => setShowFeedbackTakeover(false),
          }}
        />
      )}
      {shouldShowBottomNav && <CustomBottomNav />}
    </div>
  );
};

export default Layout;
