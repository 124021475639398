import { useNavigate } from 'react-router-dom';
import ChatIcon from '@mui/icons-material/Chat';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import classNames from 'classnames';
import { PATHS } from '../utils/constants';

//note after adding review and lessons features, can replace with `BottomNav.tsx`
const CustomBottomNav = () => {
  return (
    <div className='fixed bottom-0 left-0 w-full bg-white flex justify-around shadow-md'>
      <NavItem label='Chat' icon={<ChatIcon />} path={PATHS.CHAT} />
      <NavItem
        label='Review'
        icon={<AssignmentIcon />}
        path={PATHS.REVIEW}
        comingSoon
      />
      <NavItem
        label='Role Play'
        icon={<TheaterComedyIcon />}
        path={PATHS.ROLE_PLAY}
        comingSoon
      />
      <NavItem
        label='Lessons'
        icon={<SchoolIcon />}
        path={PATHS.LESSONS}
        comingSoon
      />
    </div>
  );
};

interface NavItemProps {
  label: string;
  icon: JSX.Element;
  path: string;
  comingSoon?: boolean;
}

const NavItem = ({ label, icon, path, comingSoon }: NavItemProps) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const onClick = () => {
    if (comingSoon) {
      setOpen(true);
    } else {
      navigate(path);
    }
  };

  return (
    <>
      <button
        className={classNames(
          'flex flex-col items-center justify-center p-3',
          comingSoon ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
        )}
        onClick={onClick}
      >
        {icon}
        <span className='text-xs font-semibold'>{label}</span>
      </button>
      <Dialog
        onClose={() => setOpen(false)}
        onClick={() => setOpen(false)}
        open={open}
      >
        <DialogTitle>{label} feature is coming soon!</DialogTitle>
      </Dialog>
    </>
  );
};

export default CustomBottomNav;
