import classNames from 'classnames';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import Testimonial from './Testimonial';

const Testimonials = () => {
  const isTabletOrLarger = useIsTabletOrLarger();

  const testimonials = [
    {
      testimony:
        '"Your app is the best way to improve my English I have seen so far. I love it!"',
      image: '/testimonialImages/alejandro.webp',
      name: 'Alejandro Re',
      description: 'Argentina',
    },
    {
      testimony:
        '"This app is great!!! I’m learning much faster than with typical language apps. The characters are engaging and the interface offers so many options."',
      image: '/testimonialImages/gary.webp',
      name: 'Gary Adams',
      description: 'USA',
    },
  ];

  return (
    <div className='flex flex-col items-center mb-8'>
      <p className='font-bold text-3xl mb-4'>Reviews</p>
      <div
        className={classNames(
          'w-full mb-4 flex gap-5 justify-center',
          !isTabletOrLarger && 'flex-col items-center',
        )}
      >
        {testimonials.map(testimonial => (
          <Testimonial key={testimonial.name} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
