import { ChangeEvent, FormEvent, useState } from 'react';
import Switch from '@mui/material/Switch';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';
import { Settings } from '../api/user/types';
import {
  LANGUAGES,
  LEVEL_SETTINGS_RADIO_NAMES,
  MODELS,
  TEXT_TO_TEXT_MODEL_OPTIONS,
} from '../utils/constants';
import { useConfigContext } from '../contexts/ConfigContext';
import Takeover from './Takeover';
import LevelRadioButtons from './LevelRadioButtons';
import CorrectionFrequencyRadioButtons from './CorrectionFrequencyRadioButtons';
import NativeLangFrequencyRadioButtons from './NativeLangFrequencyRadioButtons';
import TextToTextModelRadioButtons from './TextToTextModelRadioButtons';
import toast from 'react-hot-toast';

interface LanguageSettingsTakeoverProps {
  handleSettingsClose: () => void;
}

export const LANGUAGE_SETTINGS_TAKEOVER = 'language-settings-takeover';

const LanguageSettingsTakeover = ({
  handleSettingsClose,
}: LanguageSettingsTakeoverProps) => {
  const { user, settingsMap, updateUser } = useUserContext();
  const textTotextModelChoice =
    useConfigContext().config.featureFlags.features.textTotextModelChoice;

  const selectedLanguageSettings = settingsMap[user.selectedLanguage];

  const {
    language,
    level,
    motivation,
    correctionFrequency,
    nativeLanguageFrequency,
    showTransliteration,
    textToTextModel,
  } = selectedLanguageSettings;

  const [formData, setFormData] = useState<Settings>({
    language,
    level,
    motivation,
    correctionFrequency,
    nativeLanguageFrequency,
    showTransliteration,
    textToTextModel,
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === LEVEL_SETTINGS_RADIO_NAMES.TEXT_TO_TEXT_MODEL) {
      let model: string;

      if (e.target.value === TEXT_TO_TEXT_MODEL_OPTIONS.FAST) {
        model = MODELS.GROQ.MIXTRAL_8x7B;
      } else {
        model = MODELS.OPEN_AI.FOUT_OMNI_MINI;
      }

      setFormData({ ...formData, [e.target.name]: model });
      return;
    }

    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleToggleTransliteration = () => {
    setFormData({
      ...formData,
      showTransliteration: !formData.showTransliteration,
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!settingsHaveChanged()) {
      toast('No changes detected');
      return;
    }

    try {
      const updatedUser = await userAPI.updateUser({
        userEmail: user.email,
        settings: formData,
      });

      updateUser(updatedUser);

      toast.success('Settings updated');
    } catch (error) {
      toast.error('Error updating settings. Please try again.');
    }

    handleSettingsClose();
  };

  const settingsHaveChanged = () => {
    return (
      formData.language !== selectedLanguageSettings.language ||
      formData.level !== selectedLanguageSettings.level ||
      formData.motivation !== selectedLanguageSettings.motivation ||
      formData.correctionFrequency !==
        selectedLanguageSettings.correctionFrequency ||
      formData.nativeLanguageFrequency !==
        selectedLanguageSettings.nativeLanguageFrequency ||
      formData.showTransliteration !==
        selectedLanguageSettings.showTransliteration ||
      formData.textToTextModel !== selectedLanguageSettings.textToTextModel
    );
  };

  return (
    <Takeover
      handleCloseScreenTakeover={handleSettingsClose}
      title={`${language} Settings`}
      id={LANGUAGE_SETTINGS_TAKEOVER}
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-y-2'>
        <LevelRadioButtons {...{ level: formData.level, handleChange }} />
        <CorrectionFrequencyRadioButtons
          {...{
            correctionFrequency: formData.correctionFrequency,
            handleChange,
          }}
        />
        <NativeLangFrequencyRadioButtons
          {...{
            nativeLanguageFrequency: formData.nativeLanguageFrequency,
            handleChange,
          }}
        />

        {textTotextModelChoice && (
          <TextToTextModelRadioButtons
            {...{
              textToTextModel: formData.textToTextModel || '',
              handleChange,
            }}
          />
        )}

        {language === LANGUAGES.CHINESE && (
          <div className='absolute left-6 bottom-6'>
            <Switch
              checked={formData.showTransliteration}
              onChange={handleToggleTransliteration}
              inputProps={{ 'aria-label': 'controlled' }}
            />
            <span className='font-bold'>Pinyin</span>
          </div>
        )}
        <button
          type='submit'
          className='absolute right-4 bottom-4 border bg-blue-500 hover:bg-blue-600 text-white font-bold p-4 rounded-lg'
        >
          Update
        </button>
      </form>
    </Takeover>
  );
};

export default LanguageSettingsTakeover;
