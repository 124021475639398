import classNames from 'classnames';
import { ReactComponent as SpeakerIcon } from '../images/speaker.svg';
import Spinner from './Spinner';

interface SpeakerButtonProps {
  isFetchingAudio: boolean;
  handleSpeakerClick: () => void;
  isPlaying: boolean;
}

const SpeakerButton = ({
  isFetchingAudio,
  handleSpeakerClick,
  isPlaying,
}: SpeakerButtonProps) => {
  return (
    <>
      {isFetchingAudio ? (
        <Spinner />
      ) : (
        <button title='Speak' type='button' onClick={handleSpeakerClick}>
          <SpeakerIcon className={classNames(isPlaying && 'animate-blink')} />
        </button>
      )}
    </>
  );
};

export default SpeakerButton;
