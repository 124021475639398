import apiClient from '.';

const fetchTopicSuggestions = async () => {
  const { data } = await apiClient.get('/api/topic-suggestions');

  return data;
};

const topicSuggestionsAPI = {
  fetchTopicSuggestions,
};

export default topicSuggestionsAPI;
