import { PropsWithChildren } from 'react';
import { TeachersProvider } from '../contexts/TeachersContext';
import { UserProvider } from '../contexts/UserContext';
import { AuthProvider } from '../contexts/AuthContext';
import { ConfigProvider } from '../contexts/ConfigContext';
import { WidgetProvider } from '../contexts/WidgetContext';

const Providers = ({ children }: PropsWithChildren) => {
  return (
    <AuthProvider>
      <UserProvider>
        <ConfigProvider>
          <TeachersProvider>
            <WidgetProvider>{children}</WidgetProvider>
          </TeachersProvider>
        </ConfigProvider>
      </UserProvider>
    </AuthProvider>
  );
};

export default Providers;
