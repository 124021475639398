import { FormEvent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { allowedLanguages } from '../api/user/types';
import userAPI from '../api/user/userAPI';
import { useUserContext } from '../contexts/UserContext';
import { PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { getFlagAndLanguage } from '../utils/utils';
import useIsWindows from '../hooks/useIsWindows';
import toast from 'react-hot-toast';

const selectAndInputClasses = 'px-1 border-2 rounded-lg';

const RegistrationPage = () => {
  const { currentUser } = useAuthContext();
  const [userName, setUserName] = useState(currentUser?.displayName ?? '');
  const [nativeLanguage, setNativeLanguage] = useState('English');
  const [languageToLearn, setLanguageToLearn] = useState('Spanish');
  const [languageLevel, setLanguageLevel] = useState('Beginner');
  const { user, updateUser } = useUserContext();
  const navigate = useNavigate();
  const isWindows = useIsWindows();

  useEffect(() => {
    if (!user?.isNewUser) {
      navigate(PATHS.CHAT);
    }
  }, [navigate, user?.isNewUser]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!userName.trim()) {
      toast('Please enter a user name');
      return;
    }

    const updatedUser = await userAPI.updateUser({
      userEmail: user.email,
      userName,
      selectedLanguage: languageToLearn,
      nativeLanguage,
      isNewUser: false,
      settings: {
        language: languageToLearn,
        level: languageLevel,
        correctionFrequency: 'Sometimes',
        motivation: 'None selected',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
      },
    });

    updateUser(updatedUser);
  };

  //TODO should this be a modal?

  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center bg-blue-500 p-4'>
      <div className='mb-2 flex flex-col items-center font-extrabold whitespace-nowrap text-white'>
        <h1 className='text-xl'>Welcome to Lingua Chat!</h1>
        <h2 className='text-lg'>Let's get you set up.</h2>
      </div>
      <form
        onSubmit={handleSubmit}
        className='flex flex-col gap-y-4 bg-white p-4 rounded-lg shadow-md justify-center font-semibold'
      >
        <div className='flex items-center'>
          <label htmlFor='username' className='mr-2'>
            Username:
          </label>
          <input
            type='text'
            id='username'
            value={userName}
            onChange={e => setUserName(e.target.value)}
            className={selectAndInputClasses}
            required
          />
        </div>

        <div className='flex'>
          <label className='mr-2'>Native language:</label>
          <select
            id='native-language'
            value={nativeLanguage}
            onChange={e => setNativeLanguage(e.target.value)}
            className={selectAndInputClasses}
          >
            {allowedLanguages.map(language => (
              <option key={language} value={language}>
                {isWindows ? language : getFlagAndLanguage(language)}
              </option>
            ))}
          </select>
        </div>

        <div className='flex'>
          <label className='mr-2'>I want to learn: </label>
          <select
            value={languageToLearn}
            onChange={e => setLanguageToLearn(e.target.value)}
            className={selectAndInputClasses}
          >
            {allowedLanguages.map(language => (
              <option key={language} value={language}>
                {isWindows ? language : getFlagAndLanguage(language)}
              </option>
            ))}
          </select>
        </div>
        <div className='flex'>
          <label className='mr-2'>Proficiency level:</label>
          <select
            value={languageLevel}
            onChange={e => setLanguageLevel(e.target.value)}
            className={selectAndInputClasses}
          >
            <option value='Beginner'>Beginner</option>
            <option value='Intermediate'>Intermediate</option>
            <option value='Advanced'>Advanced</option>
          </select>
        </div>

        <button
          type='submit'
          className='bg-blue-500 text-white rounded-lg p-4 font-bold hover:bg-blue-600'
        >
          Get started!
        </button>
      </form>
    </div>
  );
};

export default RegistrationPage;
