import classNames from 'classnames';
import { MutableRefObject } from 'react';

interface MessageDropdownProps {
  dropdownRef: MutableRefObject<HTMLDivElement | null>;
  content: string;
  white?: boolean;
  prefix?: string;
}

const MessageDropdown = ({
  dropdownRef,
  content,
  white = false,
  prefix,
}: MessageDropdownProps) => {
  return (
    <div ref={dropdownRef}>
      <div
        className={classNames(
          'border-t my-2',
          white ? 'border-white' : 'border-gray-400',
        )}
      />
      <div className='flex items-center'>
        {prefix && <p className='font-bold text-sm mr-2'>{prefix}</p>}
        <p>{content}</p>
      </div>
    </div>
  );
};

export default MessageDropdown;
