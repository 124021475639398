import apiClient from '.';

const fetchTranslation = async (
  userNativeLanguage: string,
  content: string,
) => {
  const { data } = await apiClient.post('/api/translation', {
    userNativeLanguage,
    content,
  });

  return data.translation;
};

const translationAPI = {
  fetchTranslation,
};

export default translationAPI;
