import classNames from 'classnames';
import { FaMicrophone } from 'react-icons/fa6';
import useIsWindows from '../hooks/useIsWindows';
import toast from 'react-hot-toast';

interface MicroPhoneButtonProps {
  startRecording: () => void;
  stopRecording: () => void;
  areInputButtonsDisabled: boolean;
  stopAllAudios: () => void;
  isRecording: boolean;
}

const MicroPhoneButton = ({
  startRecording,
  stopRecording,
  areInputButtonsDisabled,
  stopAllAudios,
  isRecording,
}: MicroPhoneButtonProps) => {
  const isWindows = useIsWindows();

  const handleMicrophoneClick = () => {
    stopAllAudios();

    if (isWindows) {
      toast.error(
        'Microphone is not currently supported on Windows computers...working to fix that! Microphone currently works on phones, iPads, and Macbooks though!',
        { duration: 10000 },
      );

      return;
    }

    isRecording ? stopRecording() : startRecording();
  };

  return (
    <button
      type='button'
      disabled={areInputButtonsDisabled}
      title={isRecording ? 'Stop' : 'Record'}
      onClick={handleMicrophoneClick}
      className='p-4'
    >
      <FaMicrophone
        className={classNames(
          'scale-125',
          isRecording && 'animate-blink',
          areInputButtonsDisabled && 'opacity-50 cursor-not-allowed',
        )}
      />
    </button>
  );
};

export default MicroPhoneButton;
