import classNames from 'classnames';

const SendTextButton = ({
  areInputButtonsDisabled,
}: {
  areInputButtonsDisabled: boolean;
}) => (
  <button
    type='submit'
    disabled={areInputButtonsDisabled}
    title='Send'
    className={classNames(
      'bg-blue-700 hover:bg-blue-800 text-white font-bold p-3 rounded-xl',
      areInputButtonsDisabled && 'opacity-50 cursor-not-allowed',
    )}
  >
    Send
  </button>
);

export default SendTextButton;
