import classNames from 'classnames';
import Feature from './Feature';

const Features = () => {
  const features = [
    {
      text: 'Chat with bilingual AI teachers via voice or text',
      subtext: '(Feel free to use your native language!)',
      image: '/landingPageImages/chatExample.webp',
    },
    {
      text: 'Get translations with the click of a button',
      image: '/landingPageImages/translationExample.webp',
    },
    {
      text: 'Get suggestions on how to reply',
      image: '/landingPageImages/suggestionExample.webp',
    },
    {
      text: 'Each language has multiple teachers with distinct personalities',
      image: '/landingPageImages/teachersPage.webp',
    },
  ];

  return (
    <div className='flex flex-col items-center mb-8'>
      <p className='font-bold text-3xl mb-4'>Features</p>
      <div className='flex flex-col gap-5 mb-4 md:items-end'>
        {features.map(({ text, subtext, image }) => (
          <div className={classNames()} key={image}>
            <Feature {...{ text, subtext, image }} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Features;
