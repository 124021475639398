import { Component, ErrorInfo, PropsWithChildren } from 'react';

interface ErrorBoundaryState {
  error: null | Error;
}

interface FallbackErrorMessage {
  errorMessage: string;
}

interface ErrorBoundaryProps extends PropsWithChildren {
  fallback: (props: FallbackErrorMessage) => JSX.Element;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error: Error) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('ErrorBoundary caught an error: ', error, errorInfo);
  }

  render() {
    if (this.state.error) {
      const Fallback = this.props.fallback;
      return <Fallback errorMessage={this.state.error.message} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
