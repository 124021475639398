import ProfileButton from './ProfileButton';
import Takeover from './Takeover';

interface ProfileConfrimTakeoverProps {
  handleCloseScreenTakeover: () => void;
  title: string;
  id: string;
  handleConfirm: () => void;
  errorMessage: string;
}

const ProfileConfrimTakeover = ({
  handleCloseScreenTakeover,
  title,
  id,
  handleConfirm,
  errorMessage,
}: ProfileConfrimTakeoverProps) => {
  return (
    <Takeover
      handleCloseScreenTakeover={handleCloseScreenTakeover}
      title={title}
      id={id}
    >
      <div className='rounded-lg p-4 text-center flex space-y-6 flex-col mt-60 md:mt-40 bg-white justify-center items-center w-full overflow-hidden'>
        {errorMessage && <p className='text-red-500 text-xs'>{errorMessage}</p>}
        <p className='font-extrabold mb-4'>
          Are you sure you want to{' '}
          {title.charAt(0).toLowerCase() + title.slice(1)}?
        </p>
        <div className='mb-20 w-full flex justify-center'>
          <ProfileButton text={'Confirm'} handleClick={handleConfirm} />
        </div>
      </div>
    </Takeover>
  );
};

export default ProfileConfrimTakeover;
