import apiClient from '.';

interface ContactFormContent {
  email: string;
  message: string;
  name?: string;
}

const postContactFormContent = async ({
  email,
  message,
  name = 'anonymous',
}: ContactFormContent) => {
  try {
    await apiClient.post('/api/contact', {
      message,
      email,
      name,
    });
  } catch (error) {
    console.log(error);
  }
};

const contactAPI = {
  postContactFormContent,
};

export default contactAPI;
