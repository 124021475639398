import { MutableRefObject } from 'react';
import MessageDropdown from './MessageDropdown';

interface TranslationDropdownProps {
  dropdownRef: MutableRefObject<HTMLDivElement | null>;
  translation: string;
}

const TranslationDropdown = ({
  dropdownRef,
  translation,
}: TranslationDropdownProps) => (
  <MessageDropdown dropdownRef={dropdownRef} content={translation} />
);

export default TranslationDropdown;
