import { useEffect, useState } from 'react';

// Extend Navigator interface to include standalone property for iOS
interface NavigatorStandalone extends Navigator {
  standalone?: boolean;
}

const useIsPWA = (): boolean => {
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    const checkPWA = () => {
      const navigator = window.navigator as NavigatorStandalone;
      const standalone =
        window.matchMedia('(display-mode: standalone)').matches ||
        navigator.standalone === true;
      setIsPWA(standalone);
    };

    checkPWA();
  }, []);

  return isPWA;
};

export default useIsPWA;
