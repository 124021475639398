import classNames from 'classnames';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import Spinner from './Spinner';

interface RestartChatButtonProps {
  handleDeleteClick: () => void; //todo rename?
  isDisabled: boolean;
  isDeletingChat: boolean;
}

const RestartChatButton = ({
  handleDeleteClick,
  isDisabled,
  isDeletingChat,
}: RestartChatButtonProps) => {
  return (
    <div className='p-4'>
      {isDeletingChat ? (
        <Spinner />
      ) : (
        <button
          title='New chat'
          onClick={handleDeleteClick}
          disabled={isDisabled}
          className={classNames(isDisabled && 'opacity-50 cursor-not-allowed')}
        >
          <RestartAltIcon />
        </button>
      )}
    </div>
  );
};

export default RestartChatButton;
