import { useUserContext } from '../contexts/UserContext';
import { ReactComponent as VolumeOnIcon } from '../images/volumeOn.svg';
import { ReactComponent as VolumeOffIcon } from '../images/volumeOff.svg';
import { STEPS_SELECTOR_CLASSES } from '../utils/constants';

const AudioToggle = ({ toggleAutoPlay }: { toggleAutoPlay: () => void }) => {
  const { user } = useUserContext();

  return (
    <button
      onClick={toggleAutoPlay}
      className={`${STEPS_SELECTOR_CLASSES.AUDIO_TOGGLE} translate-x-1`}
    >
      {user.isAutoPlayOn ? <VolumeOnIcon /> : <VolumeOffIcon />}
    </button>
  );
};

export default AudioToggle;
