import apiClient from '.';

interface fetchSuggestionArgs {
  content: string;
  language: string;
  userNativeLanguage: string;
}

const fetchSuggestion = async ({
  content,
  language,
  userNativeLanguage,
}: fetchSuggestionArgs) => {
  const { data } = await apiClient.post('/api/suggestion', {
    content,
    language,
    userNativeLanguage,
  });

  return data;
};

const suggestionAPI = {
  fetchSuggestion,
};

export default suggestionAPI;
