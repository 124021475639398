import { FormEvent, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import { GoogleLoginButton } from 'react-social-login-buttons';
import auth from '../firebase';
import { PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import useIsDesktop from '../hooks/useIsDesktop';
import LogoAndTitle from '../components/LogoAndTitle';

const provider = new GoogleAuthProvider();

const SignupPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const isDesktop = useIsDesktop();

  const handleSignup = async (e: FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      await createUserWithEmailAndPassword(auth, email, password);

      navigate(PATHS.SUBSCRIPTION); //todo should this be subscription page?
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      isDesktop
        ? await signInWithRedirect(auth, provider)
        : await signInWithPopup(auth, provider);

      navigate(PATHS.SUBSCRIPTION); //todo should this be subscription page?
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };

  if (currentUser) return <Navigate to={PATHS.CHAT} replace />;

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-blue-500 text-white p-4'>
      <LogoAndTitle />
      <form
        onSubmit={handleSignup}
        className='w-full max-w-xs space-y-4 bg-white text-black rounded-lg p-4'
      >
        <h2 className='text-center text-2xl font-bold'>
          Let's get you signed up!
        </h2>
        {errorMessage && <p className='text-red-500 text-xs'>{errorMessage}</p>}
        <div>
          <label htmlFor='email' className='text-sm font-medium'>
            Email
          </label>
          <input
            type='email'
            id='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            className='w-full mt-1 p-2 text-sm border rounded-md font-medium bg-gray-200'
            required
          />
        </div>
        <div>
          <label htmlFor='password' className='text-sm font-medium'>
            Password
          </label>
          <input
            type='password'
            id='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            className='w-full mt-1 p-2 text-sm border rounded-md bg-gray-200'
            required
          />
        </div>
        <button
          type='submit'
          className='w-full bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md font-medium transition duration-200'
        >
          Sign Up
        </button>
        <div className='w-full'>
          <GoogleLoginButton onClick={handleGoogleSignIn}>
            <span>Sign up with Google</span>
          </GoogleLoginButton>
        </div>
        <div className='mt-4 text-center'>
          <button
            type='button'
            onClick={() => navigate(PATHS.LOGIN)}
            className='w-full font-medium hover:text-blue-600 transition duration-200'
          >
            Already have an account? Log in
          </button>
        </div>
      </form>
    </div>
  );
};

export default SignupPage;
