import { useEffect, useState } from 'react';

const DESKTOP_MEDIA_QUERY = '(min-width: 1024px)';

const useIsDesktop = () => {
  const [isDesktop, setIsDesktop] = useState(
    window.matchMedia(DESKTOP_MEDIA_QUERY).matches,
  );

  useEffect(() => {
    const handleChange = () => setIsDesktop(mql.matches);
    const mql = window.matchMedia(DESKTOP_MEDIA_QUERY);

    setIsDesktop(mql.matches); //todo do i need this
    mql.addEventListener('change', handleChange);

    return () => mql.removeEventListener('change', handleChange);
  }, []);

  return isDesktop;
};
export default useIsDesktop;
