import { MutableRefObject } from 'react';
import MessageDropdown from './MessageDropdown';

interface SuggestionDropdownProps {
  dropdownRef: MutableRefObject<HTMLDivElement | null>;
  suggestion: string;
}

const SuggestionDropdown = ({
  dropdownRef,
  suggestion,
}: SuggestionDropdownProps) => (
  <MessageDropdown
    dropdownRef={dropdownRef}
    content={suggestion}
    prefix={'Try:'}
  />
);

export default SuggestionDropdown;
