import axios from 'axios';

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  // process.env.NODE_ENV === 'development'//fixme?
  //   ? 'http://localhost:8080'
  //   : process.env.REACT_APP_BACKEND_URL,
});

export default apiClient;
