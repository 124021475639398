import { MutableRefObject } from 'react';
import MessageDropdown from './MessageDropdown';

interface CorrectionDropdownProps {
  dropdownRef: MutableRefObject<HTMLDivElement | null>;
  correction: string;
}

//todo why is it making message wider???
//todo consider adding feature flag
const CorrectionDropdown = ({
  dropdownRef,
  correction,
}: CorrectionDropdownProps) => (
  <MessageDropdown dropdownRef={dropdownRef} content={correction} white />
);

export default CorrectionDropdown;
