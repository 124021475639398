import { useEffect, useState } from 'react';

const TABLET_MEDIA_QUERY = '(min-width: 768px)';

const useIsTabletOrLarger = () => {
  const [isTabletOrLarger, setIsTabletOrLarger] = useState(
    window.matchMedia(TABLET_MEDIA_QUERY).matches,
  );

  useEffect(() => {
    const handleChange = () => setIsTabletOrLarger(mql.matches);
    const mql = window.matchMedia(TABLET_MEDIA_QUERY);

    setIsTabletOrLarger(mql.matches); //todo do i need this
    mql.addEventListener('change', handleChange);

    return () => mql.removeEventListener('change', handleChange);
  }, []);

  return isTabletOrLarger;
};

export default useIsTabletOrLarger;
