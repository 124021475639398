import apiClient from '.';

const fetchAudio = async (
  content: string,
  isFemale: boolean,
  languageCode: string,
  voice: string,
  azureVoice: string,
  ttsProvider: string,
) => {
  const { data } = await apiClient.post(
    '/api/audio',
    {
      message: content,
      isFemale,
      languageCode,
      userPreferredSpeed: 1,
      voice,
      azureVoice,
      ttsProvider,
    },
    {
      responseType: 'blob',
    },
  );

  return data;
};

const audioAPI = {
  fetchAudio,
};

export default audioAPI;
