import apiClient from '.';

interface fetchCorrectionArgs {
  prevAIMessage: string;
  content: string;
  language: string;
  userNativeLanguage: string;
}

//todo see how it does with only context of prev ai message and user message. if performing poorly, add last 5 messages in convo
const fetchCorrection = async ({
  prevAIMessage,
  content,
  language,
  userNativeLanguage,
}: fetchCorrectionArgs) => {
  const { data } = await apiClient.post('/api/correction', {
    prevAIMessage,
    content,
    language,
    userNativeLanguage,
  });

  return data;
};

const fetchCorrectionAPI = {
  fetchCorrection,
};

export default fetchCorrectionAPI;
