import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import classNames from 'classnames';
import { useTeachersContext } from '../contexts/TeachersContext';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';
import { PATHS, TRANSPARENT_GIF } from '../utils/constants';

interface TeacherProps {
  name: string;
  age: number;
  bio: string;
  _id: string;
}

const Teacher = ({ name, age, bio, _id }: TeacherProps) => {
  const { user, settingsMap, updateUser } = useUserContext();
  const { teachersAvatarURLMap } = useTeachersContext();
  const navigate = useNavigate();
  const [isImageLoading, setIsImageLoading] = useState(true);

  const handleTeacherClick = async (teacherId: string) => {
    const selectedLanguageSettings = settingsMap[user.selectedLanguage];

    if (selectedLanguageSettings.selectedTeacher === teacherId) {
      //todo rename selectedTeacher to selectedTeacherId
      navigate(PATHS.CHAT);
      return;
    }

    const updatedUser = await userAPI.updateUser({
      userEmail: user.email,
      settings: {
        ...selectedLanguageSettings,
        selectedTeacher: teacherId,
      },
    });

    updateUser(updatedUser);

    navigate(PATHS.CHAT);
  };

  return (
    <div className='w-full flex justify-between items-center p-3 md:p-4 hover:bg-gray-300 border-b-2 border-y-gray-300'>
      <button
        onClick={() => handleTeacherClick(_id)}
        key={_id}
        className='flex items-center w-full'
      >
        <img
          src={isImageLoading ? TRANSPARENT_GIF : teachersAvatarURLMap[name]}
          alt=''
          onLoad={() => setIsImageLoading(false)}
          className={classNames(
            'w-24 h-24 rounded-full object-cover shadow-xl mr-3 md:mr-4',
            isImageLoading && 'animate-pulse bg-gray-300',
          )}
        />
        <div className='flex flex-col items-start'>
          <div className='flex items-center gap-1 mb-1'>
            <p className='font-semibold'>{name}</p>
            <p className='text-gray-500 text-sm'>{age}</p>
          </div>
          <p className='text-gray-700 text-sm text-left'>{bio}</p>
        </div>
      </button>
    </div>
  );
};

export default Teacher;
