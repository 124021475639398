import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginIcon from '@mui/icons-material/Login';
import { EVENTS, PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import ContactFormTakeover from '../components/ContactFormTakeover';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import Testimonials from '../components/Testimonials';
import LogoAndTitle from '../components/LogoAndTitle';
import Features from '../components/Features';

const CONTACT_FORM_TAKEOVER = 'contact-form-takeover';

const LandingPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const isTabletOrLarger = useIsTabletOrLarger();
  const [isContactFormTakeoverOpen, setIsContactFormTakeoverOpen] =
    useState(false);

  useEffect(() => {
    if (currentUser) navigate(PATHS.CHAT);
  }, [currentUser, navigate]);

  /**
   * Handle contact form takeover blur
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === CONTACT_FORM_TAKEOVER) {
        setIsContactFormTakeoverOpen(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger]);

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-blue-500 text-white p-4'>
      <LogoAndTitle />
      <button
        onClick={() => navigate(PATHS.LOGIN)}
        className='bg-white flex gap-x-2 hover:bg-gray-200 font-bold text-black p-2 rounded-md transition duration-200 absolute top-5 right-5 shadow-lg'
      >
        <LoginIcon /> <p>Login</p>
      </button>
      <SalesPitch />
      <Testimonials />
      <Features />
      <div className='mt-8'>
        <TryForFreeButton />
      </div>
      <footer className='pb-2 flex flex-col items-center gap-y-2 pt-20'>
        <p>Copyright © 2024 Lingua Chat Inc. All rights reserved.</p>
        <div className='flex gap-x-2  items-center'>
          <a
            href='/lingua-chat-terms-of-use.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Terms of Use
          </a>
          <p className='text-xl'>|</p>
          <a
            href='/lingua-chat-privacy-policy.pdf'
            target='_blank'
            rel='noopener noreferrer'
          >
            Privacy Policy
          </a>
        </div>
        <button
          type='button'
          onClick={() => setIsContactFormTakeoverOpen(true)}
          className='bg-white hover:bg-gray-200 font-bold text-black p-2 rounded-md transition duration-200 w-fit shadow-lg'
        >
          Contact us
        </button>
      </footer>
      {isContactFormTakeoverOpen && (
        <ContactFormTakeover
          id={CONTACT_FORM_TAKEOVER}
          closeContactFormTakeover={() => setIsContactFormTakeoverOpen(false)}
        />
      )}
    </div>
  );
};

const SalesPitch = () => {
  return (
    <div className='pt-32 px-4 flex flex-col gap-y-4 mb-8 items-center justify-center'>
      <h2 className='text-5xl mb-2'>
        AI language teachers in your pocket, 24/7
      </h2>
      <ol className='list-disc text-2xl mb-8 flex flex-col gap-y-2'>
        <li>Learn languages with the power of artificial intelligence</li>
        <li>A fraction of the cost of traditional tutors</li>
        <li>Personalized to fit your learning style without the stress</li>
      </ol>
      <div className='mx-auto'>
        <TryForFreeButton />
      </div>
    </div>
  );
};

const TryForFreeButton = () => {
  const navigate = useNavigate();

  return (
    <button
      type='button'
      onClick={() => navigate(PATHS.SIGNUP)}
      className='bg-white hover:bg-gray-200 font-bold text-black p-3 shadow-lg rounded-md transition duration-200 text-lg'
    >
      Try for free
    </button>
  );
};

export default LandingPage;
