export const PATHS = {
  LANDING: '/',
  LOGIN: '/login',
  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot-password',
  CHAT: '/chat',
  TEACHERS: '/teachers',
  LESSONS: '/lessons',
  REVIEW: '/review',
  PROFILE: '/profile',
  REGISTRATION: '/registration',
  ROLE_PLAY: '/role-play',
  SUBSCRIPTION: '/subscription',
};

export const PAGES = {
  HOME: 'Home',
  CHAT: 'Chat',
  TEACHERS: 'Teachers',
  LESSONS: 'Lessons',
  REVIEW: 'Review',
  PROFILE: 'Profile',
  ROLE_PLAY: 'Role play',
};

export enum SIZES {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}

export const SPINNER_CLASSES = {
  SMALL: 'h-4 w-4 border-2',
  MEDIUM: 'h-8 w-8 border-2',
  LARGE: 'h-16 w-16 border-4',
} as const;

export const MEDIA_QUERIES = {
  DESKTOP: '(min-width: 1024px)',
  LANDSCAPE: '(orientation: landscape)',
} as const;

export const EVENTS = {
  CLICK: 'click',
  SCROLL: 'scroll',
} as const;

export const LEVEL = {
  BEGINNER: 'Beginner',
  INTERMEDIATE: 'Intermediate',
  ADVANCED: 'Advanced',
} as const;

//todo rename (not just about levels)
export const LEVEL_SETTINGS_RADIO_NAMES = {
  LEVEL: 'level',
  CORRECTION_FREQ: 'correctionFrequency',
  SPEED: 'speed',
  NATIVE_LANG_FREQ: 'nativeLanguageFrequency',
  TEXT_TO_TEXT_MODEL: 'textToTextModel',
} as const;

//todo rename
export const LEVEL_SETTINGS_RADIO_TITLES = {
  LEVEL: 'Proficiency Level',
  CORRECTION_FREQ: 'Should the teacher correct you?',
  SPEED: 'Speaking Speed',
  NATIVE_LANG_FREQ:
    "Should the teacher use your native language if you don't understand?",
  TEXT_TO_TEXT_MODEL: 'AI model selection',
} as const;

//todo rename to options?
export const CORRECTION_FREQ = {
  NEVER: 'Never',
  SOMETIMES: 'Sometimes',
  ALWAYS: 'Always',
} as const;

//todo rename to options?
export const SPEED = {
  SLOW: 'Slow',
  NORMAL: 'Normal',
} as const;

//todo rename to options?
export const NATIVE_LANG_FREQ = {
  NONE: 'None',
  SOME: 'Some',
} as const;

export const TEXT_TO_TEXT_MODEL_OPTIONS = {
  FAST: 'Fast',
  ACCURATE: 'Accurate',
} as const;

export const MODELS = {
  OPEN_AI: {
    THREE_POINT_FIVE_TURBO: 'gpt-3.5-turbo',
    FOUT_OMNI_MINI: 'gpt-4o-mini',
  },
  GROQ: {
    MIXTRAL_8x7B: 'mixtral-8x7b-32768',
  },
};

export const LANGUAGES = {
  SPANISH: 'Spanish',
  RUSSIAN: 'Russian',
  FRENCH: 'French',
  ENGLISH: 'English',
  GERMAN: 'German',
  ITALIAN: 'Italian',
  PORTUGUESE: 'Portuguese',
  CHINESE: 'Chinese',
} as const;

export const LANGUAGES_WITH_TRANSLITERATION: string[] = [
  LANGUAGES.CHINESE,
  LANGUAGES.RUSSIAN,
];

export const MESSAGE_ROLES = {
  USER: 'user',
  ASSISTANT: 'assistant',
  TYPING_ANIMATION: 'typingAnimation',
};

export const DEV_TEAM_EMAIL = 'linguachatdevteam@gmail.com';
export const PLEASE_EMAIL_DEV_TEAM = `Please try again. If it still doesn't work, please email the dev team at ${DEV_TEAM_EMAIL} (please copy and paste the error message into the email if you can).`;

export const PROFILE_PAGE_TITLES = {
  UPDATE_NATIVE_LANGUAGE: 'Update native language',
  LOGOUT: 'Logout',
  UPDATE_USERNAME: 'Update username',
  UPDATE_PASSWORD: 'Update password',
  DELETE_ALL_YOUR_CHATS: 'Delete all chats',
  DELETE_YOUR_ACCOUNT: 'Delete account',
  MANAGE_SUBSCRIPTION: 'Manage subscription',
};

export const DISCORD_SERVER_INVITE = 'https://discord.gg/GHZJMRTNwz';

export const STEPS_SELECTOR_CLASSES = {
  CHANGE_LANGUAGE_OR_TEACHER_BUTTON: 'change-language-or-teacher-button',
  SETTINGS_BUTTON: 'settings-button',
  AUDIO_TOGGLE: 'audio-toggle',
  HAMBURGER_BUTTON: 'hamburger-button',
  CHAT_HEADER: 'chat-header',
};

export const STEPS_SELECTORS = {
  CHANGE_LANGUAGE_OR_TEACHER_BUTTON_SELECTOR:
    '.' + STEPS_SELECTOR_CLASSES.CHANGE_LANGUAGE_OR_TEACHER_BUTTON,
  SETTINGS_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.SETTINGS_BUTTON,
  AUDIO_TOGGLE_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.AUDIO_TOGGLE,
  HAMBURGER_BUTTON_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.HAMBURGER_BUTTON,
  CHAT_HEADER_SELECTOR: '.' + STEPS_SELECTOR_CLASSES.CHAT_HEADER,
};

export const TRANSPARENT_GIF =
  'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
