import { FormEvent, useState } from 'react';
import feedbackAPI from '../api/feedbackAPI';
import { useUserContext } from '../contexts/UserContext';
import Takeover from './Takeover';
import toast from 'react-hot-toast';

interface FeedbackTakeoverProps {
  id: string;
  closeFeedbackTakeover: () => void;
}

//todo consider adding discord link
const FeedbackTakeover = ({
  id,
  closeFeedbackTakeover,
}: FeedbackTakeoverProps) => {
  const { user } = useUserContext();
  const [value, setValue] = useState('');

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await feedbackAPI.postFeedback(value, user._id, user.email);

    toast.success('Thanks so much for your feedback!');
    closeFeedbackTakeover();
  };

  return (
    <Takeover
      handleCloseScreenTakeover={closeFeedbackTakeover}
      title={'Feedback'}
      id={id}
    >
      <form onSubmit={handleSubmit} className='flex flex-col gap-y-4'>
        <textarea
          value={value}
          onChange={e => setValue(e.target.value)}
          className='p-2 border-2 rounded-lg h-60'
          placeholder='Please enter feedback here'
          required
        />

        <button
          type='submit'
          className='bg-blue-500 text-white rounded-lg shadow-lg p-4 font-bold hover:bg-blue-600'
        >
          Submit
        </button>
      </form>
    </Takeover>
  );
};

export default FeedbackTakeover;
