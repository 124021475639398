import { useState, useEffect } from 'react';

const useIsWindows = (): boolean => {
  const [isWindows, setIsWindows] = useState(false);

  useEffect(() => {
    if (navigator.userAgent.indexOf('Win') !== -1) {
      setIsWindows(true);
    }
  }, []);

  return isWindows;
};

export default useIsWindows;
