import { ReactComponent as TranslationIcon } from '../images/translate.svg';
import Spinner from './Spinner';

interface TranslationButtonProps {
  isFetchingTranslation: boolean;
  handleTranslationClick: () => void;
}

const TranslationButton = ({
  isFetchingTranslation,
  handleTranslationClick,
}: TranslationButtonProps) => (
  <>
    {isFetchingTranslation ? (
      <Spinner />
    ) : (
      <button title='Translate' type='button' onClick={handleTranslationClick}>
        <TranslationIcon />
      </button>
    )}
  </>
);

export default TranslationButton;
