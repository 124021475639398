import { useEffect, useState } from 'react';

const MEDIA_QUERY = '(min-width: 425px)';

const useIsMobileL = () => {
  const [isMobileL, setIsMobileL] = useState(
    window.matchMedia(MEDIA_QUERY).matches,
  );

  useEffect(() => {
    const handleChange = () => setIsMobileL(mql.matches);
    const mql = window.matchMedia(MEDIA_QUERY);

    setIsMobileL(mql.matches); //todo do i need this
    mql.addEventListener('change', handleChange);

    return () => mql.removeEventListener('change', handleChange);
  }, []);

  return isMobileL;
};

export default useIsMobileL;
