import LightbulbIcon from '@mui/icons-material/Lightbulb';
import Spinner from './Spinner';

interface SuggestionButtonProps {
  handleSuggestionClick: () => void;
  isFetchingSuggestion: boolean;
}

const SuggestionButton = ({
  handleSuggestionClick,
  isFetchingSuggestion,
}: SuggestionButtonProps) => (
  <>
    {isFetchingSuggestion ? (
      <Spinner />
    ) : (
      <button
        title='Suggest a response'
        type='button'
        onClick={handleSuggestionClick}
      >
        <LightbulbIcon className='scale-75 -translate-x-1' />
      </button>
    )}
  </>
);

export default SuggestionButton;
