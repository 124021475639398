import { loadStripe } from '@stripe/stripe-js';
import classNames from 'classnames';
import apiClient from '../api';
import { useUserContext } from '../contexts/UserContext';

const SubscribeButton = () => {
  const { user } = useUserContext();

  const handleClick = async () => {
    const stripePromise = loadStripe(
      process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY as string,
    );
    const stripe = await stripePromise;

    const { data } = await apiClient.post('/api/payments/checkout', {
      userId: user._id,
      email: user.email,
    });

    const { id } = data;

    await stripe?.redirectToCheckout({ sessionId: id });
  };

  return (
    <button
      onClick={handleClick}
      className={classNames(
        'font-bold w-40 p-3 rounded-lg shadow-lg bg-white text-black hover:bg-gray-100',
      )}
    >
      Subscribe for $4.99/month
    </button>
  );
};

export default SubscribeButton;
