import {
  createContext,
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import userAPI from '../api/user/userAPI';
import { useAuthContext } from './AuthContext';

export interface ISettings {
  language: string;
  level: string;
  motivation: string;
  correctionFrequency: string;
  speed: string;
  nativeLanguageFrequency: string;
  showTransliteration: boolean;
  selectedTeacher: string;
  textToTextModel: string;
}

export interface IUser {
  _id: string;
  userName: string;
  email: string;
  nativeLanguage: string;
  selectedLanguage: string;
  settings: ISettings[];
  lastLoggedIn: string;
  isAutoPlayOn: boolean;
  isNewUser: boolean;
  hasUserSeenTutorial: boolean;
  firebaseUid: string;
  stripeCustomerId: string | null;
  subscriptionId: string | null;
  planActive: Boolean | null;
  planExpires: number | null;
  isMicrophoneOnRight: boolean;
}

interface IUserContext {
  user: IUser;
  settingsMap: Record<string, ISettings>;
  updateUser: (userData: IUser) => void;
  isFetchingUser: boolean;
  isErrorFetchingUser: boolean;
}

const userInitialState: IUser = {
  _id: '',
  userName: '',
  email: '',
  nativeLanguage: '',
  selectedLanguage: '',
  settings: [
    {
      language: '',
      level: '',
      motivation: '',
      correctionFrequency: '',
      speed: '',
      nativeLanguageFrequency: '',
      selectedTeacher: '',
      showTransliteration: true,
      textToTextModel: '',
    },
  ],
  lastLoggedIn: '',
  isAutoPlayOn: true,
  isNewUser: false,
  hasUserSeenTutorial: true,
  firebaseUid: '',
  stripeCustomerId: null,
  subscriptionId: null,
  planActive: null,
  planExpires: null,
  isMicrophoneOnRight: true,
};

const UserContext = createContext<IUserContext>({
  user: userInitialState,
  settingsMap: {},
  updateUser: (_userData: IUser) => {},
  isFetchingUser: true,
  isErrorFetchingUser: false,
});

export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }: PropsWithChildren) => {
  const [user, setUser] = useState<IUser>(userInitialState);
  const [isFetchingUser, setIsFetchingUser] = useState(true);
  const [isErrorFetchingUser, setIsErrorFetchingUser] = useState(false);
  const [settingsMap, setSettingsMap] = useState<Record<string, ISettings>>({});
  const { currentUser } = useAuthContext();

  const updateUser = (userData: IUser) => {
    setUser(userData);
  };

  useEffect(() => {
    const fetchUser = async (email: string) => {
      try {
        const user = await userAPI.fetchOrCreateUser(
          email,
          currentUser?.uid as string,
        );

        setUser(user);
      } catch (error) {
        setIsErrorFetchingUser(true);
      }
    };

    if (currentUser?.email) {
      fetchUser(currentUser.email);
    }
  }, [currentUser?.email, currentUser?.uid]);

  useEffect(() => {
    if (!user.email) return;

    const getSettingsMap = (settings: ISettings[]) => {
      const settingsMap: Record<string, ISettings> = {};

      settings.forEach(setting => {
        settingsMap[setting.language] = setting;
      });

      return settingsMap;
    };

    const settingsMap = getSettingsMap(user.settings);

    setSettingsMap(settingsMap);
  }, [user.email, user.settings]);

  useEffect(() => {
    if (user.email && Object.keys(settingsMap).length > 0) {
      setIsFetchingUser(false);
    }
  }, [currentUser, settingsMap, user]);

  return (
    <UserContext.Provider
      value={{
        user,
        settingsMap,
        updateUser,
        isFetchingUser,
        isErrorFetchingUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
