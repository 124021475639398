import { PropsWithChildren } from 'react';
import { useUserContext } from '../contexts/UserContext';
import { useConfigContext } from '../contexts/ConfigContext';
import { useTeachersContext } from '../contexts/TeachersContext';
import { SIZES } from '../utils/constants';
import Spinner from './Spinner';
import Oops from './Oops';

const AppInit = ({ children }: PropsWithChildren) => {
  const { isFetchingUser, isErrorFetchingUser } = useUserContext();
  const { isFetchingConfig, isErrorFetchingConfig } = useConfigContext();
  const { isFetchingTeachers, isErrorFetchingTeachers } = useTeachersContext();

  if (isFetchingUser || isFetchingConfig || isFetchingTeachers) {
    return <Spinner fullScreen size={SIZES.LARGE} />;
  }

  if (isErrorFetchingUser) {
    return <Oops errorMessage={'Error fetching user.'} />;
  }

  if (isErrorFetchingConfig) {
    return <Oops errorMessage={'Error fetching config.'} />;
  }

  if (isErrorFetchingTeachers) {
    return <Oops errorMessage={'Error fetching teachers.'} />;
  }

  return <>{children}</>;
};

export default AppInit;
