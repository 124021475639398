const calculateRMS = (audioBuffer: AudioBuffer) => {
  const channelData = audioBuffer.getChannelData(0);
  let sumOfSquares = 0;
  for (let i = 0; i < channelData.length; i++) {
    sumOfSquares += channelData[i] * channelData[i];
  }
  const rms = Math.sqrt(sumOfSquares / channelData.length);
  return rms;
};

export const getIsAudioSilent = (
  audioBuffer: AudioBuffer,
  threshold: number = 0.01,
) => {
  const rms = calculateRMS(audioBuffer);
  return rms < threshold;
};

//todo change use general instructions. but that should be on backend.
export const generateUserPreferencesPrompt = (
  userName: string,
  level: string,
  language: string,
  correctionFrequency: string,
  userNativeLanguage: string,
  nativeLanguageFrequency: string,
) => {
  const userPreferencesPrompt = `Student is ${userName}, ${level} level learning ${language}. Prefers to be corrected ${correctionFrequency} when they make a mistake.You may use users native language, ${userNativeLanguage} ${nativeLanguageFrequency} as needed, but try to stay in ${language}.Tailor interactions accordingly.`;

  return userPreferencesPrompt;
};

export const capitalizeFirstLetter = (string: string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const delay = async (ms: number) => {
  await new Promise(res => setTimeout(() => res(null), ms));
};

export const getFlag = (language: string) => {
  switch (language) {
    case 'English':
      return '🇺🇸';
    case 'Spanish':
      return '🇪🇸';
    case 'French':
      return '🇫🇷';
    case 'German':
      return '🇩🇪';
    case 'Italian':
      return '🇮🇹';
    case 'Portuguese':
      return '🇵🇹';
    case 'Russian':
      return '🇷🇺';
    case 'Chinese':
      return '🇨🇳';
    default:
      return '';
  }
};

export const getFlagAndLanguage = (language: string) => {
  return `${getFlag(language)} ${language}`;
};
