import {
  useContext,
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
} from 'react';
import configAPI from '../api/configAPI';
import { useUserContext } from './UserContext';

interface NavigationConfig {
  review: boolean;
  rolePlay: boolean;
  lessons: boolean;
}

interface FeaturesConfig {
  topicSuggestions: boolean;
  isSystemPromptUsingSummaries: boolean;
  corrections: boolean;
  textTotextModelChoice: boolean;
}

interface FeatureFlagsConfig {
  navigation: NavigationConfig;
  features: FeaturesConfig;
}

interface Config {
  featureFlags: FeatureFlagsConfig;
  releaseDate: string;
  forceRefresh: boolean;
}

interface IConfigContext {
  config: Config;
  isFetchingConfig: boolean;
  isErrorFetchingConfig: boolean;
}

const configInitialState: Config = {
  featureFlags: {
    navigation: {
      review: false,
      rolePlay: false,
      lessons: false,
    },
    features: {
      topicSuggestions: false,
      isSystemPromptUsingSummaries: false,
      corrections: false,
      textTotextModelChoice: false,
    },
  },
  releaseDate: '',
  forceRefresh: false,
};

const ConfigContext = createContext<IConfigContext>({
  config: configInitialState,
  isFetchingConfig: true,
  isErrorFetchingConfig: false,
});

export const useConfigContext = () => useContext(ConfigContext);

export const ConfigProvider = ({ children }: PropsWithChildren) => {
  const { user } = useUserContext();
  const [config, setConfig] = useState<Config>(configInitialState);
  const [isFetchingConfig, setIsFetchingConfig] = useState(true);
  const [isErrorFetchingConfig, setIsErrorFetchingConfig] = useState(false);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await configAPI.fetchConfig();
        setConfig(config);
      } catch (error) {
        setIsErrorFetchingConfig(true);
      }
    };

    let interval: NodeJS.Timeout;

    if (user?.email) {
      fetchConfig();
      interval = setInterval(fetchConfig, 60000 * 10);
    }

    return () => {
      clearInterval(interval);
    };
  }, [user?.email]);

  useEffect(() => {
    if (config?.releaseDate) {
      setIsFetchingConfig(false);
    }
  }, [config?.releaseDate]);

  return (
    <ConfigContext.Provider
      value={{
        config,
        isFetchingConfig,
        isErrorFetchingConfig,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
