import { PropsWithChildren } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import classNames from 'classnames';

interface TakeoverProps extends PropsWithChildren {
  handleCloseScreenTakeover: () => void;
  title: string;
  id: string;
}

const Takeover = ({
  handleCloseScreenTakeover,
  title,
  id,
  children,
}: TakeoverProps) => {
  return (
    <div
      id={id}
      className='fixed inset-0 flex items-center justify-center lg:bg-gray-500 lg:bg-opacity-50 z-50'
    >
      <div className='relative h-full w-full overflow-y-auto px-6 py-4 lg:h-[42rem] lg:w-[42rem] bg-gray-100 lg:rounded-2xl'>
        {/* Title and Close Button */}
        <div className='mb-4 lg:mb-2 flex items-center justify-between'>
          <h1
            className={classNames(
              'font-extrabold text-2xl',
              title === 'Contact us' && 'text-black',
            )}
          >
            {title}
          </h1>
          <button
            title='Close'
            className={classNames(
              'font-bold p-2',
              title === 'Contact us' && 'text-black',
            )}
            onClick={handleCloseScreenTakeover}
          >
            <CloseIcon />
          </button>
        </div>
        {children}
      </div>
    </div>
  );
};

export default Takeover;
