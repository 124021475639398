import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../contexts/AuthContext';
import { PATHS } from '../utils/constants';
import { useUserContext } from '../contexts/UserContext';

//todo do i need this anymore? maybe just hook in app.tsx
const ProtectedRoutesWrapper = () => {
  const { currentUser } = useAuthContext();
  const { user } = useUserContext();

  // Convert current date to Unix timestamp (seconds since epoch)
  const currentTimestamp = Math.floor(Date.now() / 1000);

  if (!currentUser) {
    return <Navigate to={PATHS.LOGIN} replace />; //todo test if this works!
    //TODO see note in SubscriptionPage about redirecting users with expired plans
  } else if (
    !user?.planActive ||
    (user?.planExpires && user.planExpires < currentTimestamp)
  ) {
    return <Navigate to={PATHS.SUBSCRIPTION} replace />;
  }

  return <Outlet />;
};

export default ProtectedRoutesWrapper;
