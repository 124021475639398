import { ChangeEvent } from 'react';
import {
  LEVEL_SETTINGS_RADIO_TITLES,
  LEVEL_SETTINGS_RADIO_NAMES,
  TEXT_TO_TEXT_MODEL_OPTIONS,
  MODELS,
} from '../utils/constants';
import RadioButtons from './RadioButtons';

interface TextToTextModelRadioButtonsProps {
  textToTextModel: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const TextToTextModelRadioButtons = ({
  textToTextModel,
  handleChange,
}: TextToTextModelRadioButtonsProps) => {
  const getSelectedOption = (textToTextModel: string) => {
    if (textToTextModel === MODELS.GROQ.MIXTRAL_8x7B) {
      return TEXT_TO_TEXT_MODEL_OPTIONS.FAST;
    }

    return TEXT_TO_TEXT_MODEL_OPTIONS.ACCURATE;
  };

  return (
    <RadioButtons
      title={LEVEL_SETTINGS_RADIO_TITLES.TEXT_TO_TEXT_MODEL}
      options={Object.values(TEXT_TO_TEXT_MODEL_OPTIONS)}
      name={LEVEL_SETTINGS_RADIO_NAMES.TEXT_TO_TEXT_MODEL}
      selectedOption={getSelectedOption(textToTextModel)}
      onChange={handleChange}
    />
  );
};

export default TextToTextModelRadioButtons;
