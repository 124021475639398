import {
  createContext,
  useState,
  useEffect,
  useContext,
  PropsWithChildren,
} from 'react';
import teachersAPI from '../api/teachersAPI';
import { useUserContext } from './UserContext';

export interface ITeacher {
  _id: string;
  name: string;
  age: number;
  language: string;
  languageCode: string;
  ttsProvider: string;
  voice: string;
  azureVoice: string;
  bio: string;
  prompt: string;
  isFemale: boolean;
}

interface ITeachersContext {
  teachers: ITeacher[];
  teachersByLanguageMap: Record<string, ITeacher[]>;
  teachersAvatarURLMap: Record<string, string>;
  teachersMap: Record<string, ITeacher>;
  isFetchingTeachers: boolean;
  isErrorFetchingTeachers: boolean;
}

const TeachersContext = createContext<ITeachersContext>({
  teachers: [],
  teachersByLanguageMap: {},
  teachersAvatarURLMap: {},
  teachersMap: {},
  isFetchingTeachers: true,
  isErrorFetchingTeachers: false,
});

export const useTeachersContext = () => useContext(TeachersContext);

export const TeachersProvider = ({ children }: PropsWithChildren) => {
  const { user } = useUserContext();
  const [teachers, setTeachers] = useState<ITeacher[]>([]);
  const [isFetchingTeachers, setIsFetchingTeachers] = useState(true);
  const [isErrorFetchingTeachers, setIsErrorFetchingTeachers] = useState(false);
  const [teachersByLanguageMap, setTeachersByLanguageMap] = useState<
    Record<string, ITeacher[]>
  >({});
  const [teachersAvatarURLMap, setTeachersAvatarURLMap] = useState<
    Record<string, string>
  >({});
  const [teachersMap, setTeachersMap] = useState<Record<string, ITeacher>>({});

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const teachers = await teachersAPI.fetchTeachers();

        setTeachers(teachers);
      } catch (error) {
        setIsErrorFetchingTeachers(true);
      }
    };

    if (user?.email) {
      fetchTeachers();
    }
  }, [user?.email]);

  useEffect(() => {
    if (teachers.length < 1) return;

    const getTeachersByLanguageMap = (teachers: ITeacher[]) => {
      const teachersByLanguageMap: Record<string, ITeacher[]> = {};

      teachers.forEach(teacher => {
        if (!teachersByLanguageMap[teacher.language]) {
          teachersByLanguageMap[teacher.language] = [teacher];
        } else {
          teachersByLanguageMap[teacher.language].push(teacher);
        }
      });

      return teachersByLanguageMap;
    };

    const getTeachersMap = (teachers: ITeacher[]) => {
      const teachersMap: Record<string, ITeacher> = {};

      teachers.forEach(teacher => {
        teachersMap[teacher._id] = teacher;
      });

      return teachersMap;
    };

    const teachersByLanguageMap = getTeachersByLanguageMap(teachers);
    setTeachersByLanguageMap(teachersByLanguageMap);

    const teachersMap = getTeachersMap(teachers);
    setTeachersMap(teachersMap);
  }, [teachers]);

  useEffect(() => {
    if (teachers.length < 1) return;

    const getTeachersAvatarURLMap = (teachers: ITeacher[]) => {
      const teachersAvatarURLMap: Record<string, string> = {};

      teachers.forEach(({ name }) => {
        const avatarURL = `teacherImages/${name
          .toLowerCase()
          .replace(' ', '-')}.webp`;

        teachersAvatarURLMap[name] = avatarURL;
      });

      return teachersAvatarURLMap;
    };

    const teachersAvatarURLMap = getTeachersAvatarURLMap(teachers);

    setTeachersAvatarURLMap(teachersAvatarURLMap);
  }, [teachers]);

  useEffect(() => {
    if (teachers.length < 1) return;

    if (
      teachers.length > 0 &&
      Object.keys(teachersByLanguageMap).length > 0 &&
      Object.keys(teachersAvatarURLMap).length > 0 &&
      Object.keys(teachersMap).length > 0
    ) {
      setIsFetchingTeachers(false);
    }
  }, [
    teachers.length,
    teachersAvatarURLMap,
    teachersByLanguageMap,
    teachersMap,
  ]);

  return (
    <TeachersContext.Provider
      value={{
        teachers,
        teachersByLanguageMap,
        teachersAvatarURLMap,
        teachersMap,
        isFetchingTeachers,
        isErrorFetchingTeachers,
      }}
    >
      {children}
    </TeachersContext.Provider>
  );
};
