import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import Spinner from './Spinner';

interface CorrectionButtonProps {
  isFetchingCorrection: boolean;
  handleCorrectionClick: () => void;
}

//todo consider jsut showing for last user message
const CorrectionButton = ({
  isFetchingCorrection,
  handleCorrectionClick,
}: CorrectionButtonProps) => {
  return (
    <>
      {isFetchingCorrection ? (
        <Spinner white />
      ) : (
        <button title='Speak' type='button' onClick={handleCorrectionClick}>
          <AutoFixHighIcon className='text-white scale-75' />
        </button>
      )}
    </>
  );
};

export default CorrectionButton;
