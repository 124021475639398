import {
  useContext,
  useState,
  useEffect,
  createContext,
  PropsWithChildren,
} from 'react';
import { User } from 'firebase/auth';
import { Routes, Route, Navigate } from 'react-router-dom';
import auth from '../firebase';
import ForgotPassWordPage from '../pages/ForgotPassWordPage';
import SignupPage from '../pages/SignupPage';
import { PATHS } from '../utils/constants';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/LoginPage';
import ScrollToTop from '../components/ScrollToTop';
import useIsPWA from '../hooks/useIsPWA';
import { Toaster } from 'react-hot-toast';

interface IAuthContext {
  currentUser: User | null;
}

const AuthContext = createContext<IAuthContext>({
  currentUser: null,
});

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const isPwa = useIsPWA();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setCurrentUser(user);
      }
    });

    return unsubscribe;
  }, [currentUser]); //note dep arr used to be empty

  if (!currentUser) {
    return (
      <>
        <ScrollToTop />
        <Routes>
          <Route path={PATHS.LANDING} element={<LandingPage />} />
          <Route path={PATHS.LOGIN} element={<LoginPage />} />
          <Route path={PATHS.SIGNUP} element={<SignupPage />} />
          <Route
            path={PATHS.FORGOT_PASSWORD}
            element={<ForgotPassWordPage />}
          />

          {isPwa ? (
            <Route path='*' element={<Navigate to={PATHS.LOGIN} replace />} />
          ) : (
            <Route path='*' element={<Navigate to={PATHS.LANDING} replace />} />
          )}
        </Routes>
        <Toaster />
      </>
    );
  }

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  );
};
