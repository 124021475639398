import apiClient from '..';
import { UpdateUserArgs } from './types';

const updateUser = async ({
  userEmail,
  userName,
  selectedLanguage,
  nativeLanguage,
  isAutoPlayOn,
  settings,
  isNewUser,
  hasUserSeenTutorial,
  isMicrophoneOnRight,
}: UpdateUserArgs) => {
  const { data } = await apiClient.patch(
    `/api/users/settings/${encodeURIComponent(userEmail)}`,
    {
      userName,
      selectedLanguage,
      nativeLanguage,
      isAutoPlayOn,
      settings,
      isNewUser,
      hasUserSeenTutorial,
      isMicrophoneOnRight,
    },
  );

  return data;
};

const updateUsername = async (email: string, newUserName: string) => {
  const { data } = await apiClient.patch(
    `/api/users/${encodeURIComponent(email)}`,
    {
      newUserName,
    },
  );

  return data;
};

const deleteUserChats = async (email: string) => {
  await apiClient.patch(`/api/users/${encodeURIComponent(email)}/delete-chats`);
};

const deleteUser = async (email: string, subscriptionId: string) => {
  await apiClient.delete(
    `/api/users/${encodeURIComponent(email)}/${subscriptionId}`,
  );
};

const fetchOrCreateUser = async (email: string, firebaseUid: string) => {
  //todo add type to userObj
  const userObj = {
    userName: email.split('@')[0],
    email,
    nativeLanguage: 'English',
    selectedLanguage: 'Spanish',
    isNewUser: true,
    settings: [
      {
        language: 'Spanish',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65ac4ecbac73ecde2c86a635',
      },
      {
        language: 'Russian',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65ac5021ac73ecde2c86a63d',
      },
      {
        language: 'French',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65f307701cc3434dae08f3bd',
      },
      {
        language: 'English',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65f3087d1cc3434dae08f3d0',
      },
      {
        language: 'German',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65f308211cc3434dae08f3cc',
      },
      {
        language: 'Italian',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65f309241cc3434dae08f3da',
      },
      {
        language: 'Portuguese',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '65f309581cc3434dae08f3dc',
      },
      {
        language: 'Chinese',
        level: 'Beginner',
        motivation: 'None selected',
        correctionFrequency: 'Sometimes',
        speed: 'Normal',
        nativeLanguageFrequency: 'Some',
        selectedTeacher: '663be3f6a718edfb0a85cefc',
        showTransliteration: true,
      },
    ],
    lastLoggedIn: Date.now().toString(),
    isAutoPlayOn: true,
    firebaseUid,
    stripeCustomerId: null,
    subscriptionId: null,
    planActive: false,
    planExpires: null,
    isMicrophoneOnRight: true,
  };

  const { data } = await apiClient.post(
    `/api/users/${encodeURIComponent(email)}`,
    userObj,
  );

  return data;
};

const userAPI = {
  updateUser,
  updateUsername,
  deleteUserChats,
  deleteUser,
  fetchOrCreateUser,
};

export default userAPI;
