interface ProfileButtonProps {
  text: string;
  handleClick: () => void;
}

const ProfileButton = ({ text, handleClick }: ProfileButtonProps) => (
  <button
    onClick={handleClick}
    className='bg-blue-500 shadow-lg w-2/3 max-w-xs text-white rounded-lg p-4 font-bold hover:bg-blue-600'
  >
    {text}
  </button>
);

export default ProfileButton;
