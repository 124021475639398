import { SyntheticEvent } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { useWidgetContext } from '../contexts/WidgetContext';
import { STEPS_SELECTOR_CLASSES } from '../utils/constants';

const HamburgerButton = () => {
  const { toggleIsNavSliderOpen } = useWidgetContext();

  const handleClick = (e: SyntheticEvent) => {
    e.stopPropagation();
    toggleIsNavSliderOpen();
  };

  return (
    <button
      title='Open menu'
      onClick={handleClick}
      className={`z-10 p-4 ${STEPS_SELECTOR_CLASSES.HAMBURGER_BUTTON}`}
    >
      <MenuRoundedIcon />
    </button>
  );
};

export default HamburgerButton;
