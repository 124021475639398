import apiClient from '.';

const fetchTeachers = async () => {
  const { data } = await apiClient.get('/api/teachers');

  return data;
};

const teachersAPI = {
  fetchTeachers,
};

export default teachersAPI;
