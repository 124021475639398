import IosShareIcon from '@mui/icons-material/IosShare';
import { useWidgetContext } from '../contexts/WidgetContext';

const link = 'https://www.linguachat.ai';

const ShareButton = () => {
  const { toggleIsNavSliderOpen } = useWidgetContext();

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: 'Lingua Chat',
          text: 'Checkout Lingua Chat. Learn languages with the power of AI',
          url: link,
        });
      } catch (error) {
        console.error('Error sharing: ', error);
      }
    } else {
      try {
        // Fallback for devices/browsers that do not support Web Share API
        const smsUrl = `sms:?&body=Checkout Lingua Chat. Learn languages with the power of AI: ${link}`;
        window.location.href = smsUrl;
      } catch (error) {
        console.error('Error sharing: ', error);
      }
    }

    toggleIsNavSliderOpen();
  };

  return (
    <button
      onClick={handleShare}
      className='flex items-center w-full rounded-lg mb-1 px-4 py-2 hover:bg-blue-600 hover:text-white md:py-3 font-semibold'
    >
      <IosShareIcon className='mr-2' />
      <p>Share Lingua Chat</p>
    </button>
  );
};

export default ShareButton;
