import { ITeacher } from '../contexts/TeachersContext';
import Teacher from './Teacher';

interface TeachersProps {
  teachers: ITeacher[];
}

const Teachers = ({ teachers }: TeachersProps) => (
  <div className='flex-grow bg-gray-200 overflow-y-scroll'>
    {teachers.map(({ name, age, bio, _id }) => (
      <Teacher {...{ name, age, bio, _id, key: _id }} />
    ))}
  </div>
);

export default Teachers;
