import { Dispatch, SetStateAction, useState } from 'react';
import { PROFILE_PAGE_TITLES } from '../utils/constants';
import Takeover from './Takeover';

interface ProfileUpdateTakeoverProps {
  handleCloseScreenTakeover: () => void;
  title: string;
  id: string;
  handleSubmit: (newPassword: string) => void;
  errorMessage: string;
  setErrorMessage: Dispatch<SetStateAction<string>>;
  currentUsername?: string;
  currentNativeLanguage?: string;
}

const ProfileUpdateTakeover = ({
  handleCloseScreenTakeover,
  title,
  id,
  handleSubmit,
  errorMessage,
  setErrorMessage,
  currentUsername = '',
  currentNativeLanguage = '',
}: ProfileUpdateTakeoverProps) => {
  const getInitialValue = () => {
    if (title === PROFILE_PAGE_TITLES.UPDATE_USERNAME) return currentUsername;
    if (title === PROFILE_PAGE_TITLES.UPDATE_NATIVE_LANGUAGE)
      return currentNativeLanguage;

    return '';
  };
  const [value, setValue] = useState(getInitialValue());
  let label = title.slice(7).charAt(0).toUpperCase() + title.slice(8);
  if (title === PROFILE_PAGE_TITLES.UPDATE_USERNAME)
    label = 'What should teachers call you?';
  if (title === PROFILE_PAGE_TITLES.UPDATE_PASSWORD) label = 'New Password';

  return (
    <Takeover
      handleCloseScreenTakeover={handleCloseScreenTakeover}
      title={title}
      id={id}
    >
      <div className='flex justify-center'>
        <form
          onSubmit={e => {
            e.preventDefault();
            handleSubmit(value);
          }}
          className='w-full mt-60 md:mt-32 max-w-xs space-y-6 bg-white rounded-lg p-4'
        >
          {errorMessage && (
            <p className='text-red-500 text-xs'>{errorMessage}</p>
          )}
          <>
            <label htmlFor='text' className='text-sm font-medium'>
              {label}
            </label>
            <input
              id='text'
              value={value}
              onChange={e => {
                setErrorMessage('');
                setValue(e.target.value);
              }}
              className='w-full mt-1 p-2 text-sm border rounded-md bg-gray-200'
              required
            />
          </>
          <button
            type='submit'
            className='w-full font-medium bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md transition duration-200'
          >
            Update
          </button>
        </form>
      </div>
    </Takeover>
  );
};

export default ProfileUpdateTakeover;
