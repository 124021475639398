import { useState, useEffect, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  GoogleAuthProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { GoogleLoginButton } from 'react-social-login-buttons';
import { useAuthContext } from '../contexts/AuthContext';
import { PATHS } from '../utils/constants';
import auth from '../firebase';
import LogoAndTitle from '../components/LogoAndTitle';

const provider = new GoogleAuthProvider();

const LoginPage = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (currentUser) navigate(PATHS.CHAT);
  }, [currentUser, navigate]);

  const handleLogin = async (e: FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(PATHS.CHAT);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      await signInWithPopup(auth, provider);

      navigate(PATHS.CHAT);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage('An unexpected error occurred.');
      }
    }
  };

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-blue-500 text-white p-4'>
      <LogoAndTitle />
      <form
        onSubmit={handleLogin}
        className='w-full max-w-xs space-y-4 bg-white text-black rounded-lg p-4 mb-4'
      >
        <h2 className='text-center text-2xl font-bold'>Log in</h2>
        {errorMessage && <p className='text-red-500 text-xs'>{errorMessage}</p>}
        <div>
          <label htmlFor='email' className='text-sm font-medium'>
            Email
          </label>
          <input
            type='email'
            id='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            className='w-full mt-1 p-2 text-sm border rounded-md bg-gray-200'
            required
          />
        </div>
        <div>
          <label htmlFor='password' className='text-sm font-medium'>
            Password
          </label>
          <input
            type='password'
            id='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            className='w-full mt-1 p-2 text-sm border rounded-md bg-gray-200'
            required
          />
        </div>
        <button
          type='submit'
          className='w-full font-medium bg-blue-500 hover:bg-blue-600 text-white p-2 rounded-md transition duration-200'
        >
          Log in
        </button>
        <div className='w-full'>
          <GoogleLoginButton onClick={handleGoogleSignIn} />
        </div>

        <button
          type='button'
          onClick={() => navigate(PATHS.FORGOT_PASSWORD)}
          className='w-full font-medium hover:text-blue-600 transition duration-200'
        >
          Forgot password?
        </button>
        <button
          type='button'
          onClick={() => navigate(PATHS.SIGNUP)}
          className='w-full font-medium hover:text-blue-600 transition duration-200'
        >
          Need an account? Sign up
        </button>
      </form>
    </div>
  );
};

export default LoginPage;
