import { Tooltip } from 'react-tooltip';

interface WordProps {
  word: string;
  handleWordClick: (word: string) => Promise<void>;
  translation: string;
}
//todo delete this trigger comment
/**
 * For use in `Message`
 */
const Word = ({ word, handleWordClick, translation }: WordProps) => (
  <span
    onClick={() => handleWordClick(word)}
    data-tooltip-id={`tooltip-${word}`}
    data-tooltip-content={translation}
  >
    {word + ' '}
    {translation && <Tooltip content={translation} id={`tooltip-${word}`} />}
  </span>
);

export default Word;
