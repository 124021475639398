import classNames from 'classnames';
import { useState } from 'react';
import AudioToggle from './AudioToggle';

interface HeroAndAudioToggleProps {
  image: string;
  name: string;
  toggleAutoPlay: () => void;
}

const HeroAndAudioToggle = ({
  image,
  name,
  toggleAutoPlay,
}: HeroAndAudioToggleProps) => {
  const [isImageLoading, setIsImageLoading] = useState(true);

  return (
    <div className='flex mr-4'>
      <div className='flex flex-col items-center'>
        <img
          src={image}
          alt=''
          onLoad={() => setIsImageLoading(false)}
          className={classNames(
            'rounded-full overflow-hidden object-cover w-12 h-12',
            isImageLoading && 'animate-pulse bg-gray-300',
          )}
        />
        <p className='text-center'>{name.split(' ')[0]}</p>
      </div>
      <AudioToggle {...{ toggleAutoPlay }} />
    </div>
  );
};

export default HeroAndAudioToggle;
