// import { RiPinyinInput } from 'react-icons/ri';

const PinyinButton = ({
  handleTransliterationClick,
}: {
  handleTransliterationClick: () => void;
}) => (
  <button
    title='Pinyin'
    type='button'
    onClick={handleTransliterationClick}
    className='text-xs font-bold'
  >
    {/* <RiPinyinInput /> */}Pinyin
  </button>
);

export default PinyinButton;
