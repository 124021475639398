import { StepType, TourProvider } from '@reactour/tour';
import { PropsWithChildren } from 'react';
import { STEPS_SELECTORS } from '../utils/constants';

const Tutorial = ({ children }: PropsWithChildren) => {
  const getStep = (selector: string, content: string): StepType => ({
    selector: selector,
    content: <p className='p-2'>{content}</p>,
    styles: {
      badge: (base: { [key: string]: unknown }) => ({
        ...base,
        display: 'none',
      }),
    },
  });

  const steps: StepType[] = [
    {
      ...getStep(
        STEPS_SELECTORS.CHAT_HEADER_SELECTOR,
        'Welcome to Lingua Chat! Let’s show you around.',
      ),
      position: 'center',
      padding: 0,
    },
    getStep(
      STEPS_SELECTORS.CHANGE_LANGUAGE_OR_TEACHER_BUTTON_SELECTOR,
      'Change teacher or language here',
    ),
    getStep(STEPS_SELECTORS.AUDIO_TOGGLE_SELECTOR, 'Mute teacher here'),
    getStep(
      STEPS_SELECTORS.SETTINGS_BUTTON_SELECTOR,
      'Change language settings here',
    ),
    getStep(
      STEPS_SELECTORS.HAMBURGER_BUTTON_SELECTOR,
      'Access the tutorial anytime from the menu. You can leave feedback using the menu as well (any feedback is greatly appreciated)!',
    ),
    {
      ...getStep(
        STEPS_SELECTORS.CHAT_HEADER_SELECTOR,
        `One last thing. The best way to use the current version of the app is to tell the AI
          what you would like to focus on, for example, "Teach me vocabulary for ordering train tickets, then
          help me practice doing so." Have fun!!!`,
      ),
      position: 'center',
      padding: 0,
    },
  ];

  return (
    <TourProvider steps={steps} disableInteraction={true} startAt={0}>
      {children}
    </TourProvider>
  );
};

export default Tutorial;
