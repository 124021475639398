import apiClient from '.';

const postFeedback = async (
  feedback: string,
  userId: string,
  email: string,
) => {
  const { data } = await apiClient.post('/api/feedback', {
    feedback,
    userId,
    email,
  });

  return data;
};

const feedbackAPI = {
  postFeedback,
};

export default feedbackAPI;
