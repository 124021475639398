import SuggestionButton from './SuggestionButton';
import TranslationButton from './TranslationButton';
import SpeakerButton from './SpeakerButton';
import PinyinButton from './PinyinButton';

interface AIMessageButtonsProps {
  isFetchingAudio: boolean;
  handleSpeakerClick: () => void;
  isPlaying: boolean;
  isFetchingTranslation: boolean;
  handleTranslationClick: () => void;
  isFetchingSuggestion: boolean;
  handleSuggestionClick: () => void;
  isLastAIMessage: boolean;
  handleTransliterationClick: () => void;
  showTransliteration: boolean;
  isChinese: boolean;
}

const AIMessageButtons = ({
  isFetchingAudio,
  handleSpeakerClick,
  isPlaying,
  isFetchingTranslation,
  handleTranslationClick,
  isFetchingSuggestion,
  handleSuggestionClick,
  isLastAIMessage,
  handleTransliterationClick,
  showTransliteration,
  isChinese,
}: AIMessageButtonsProps) => {
  return (
    <div className='flex items-center gap-x-2 py-1'>
      <SpeakerButton
        {...{
          isFetchingAudio,
          handleSpeakerClick,
          isPlaying,
        }}
      />
      <TranslationButton
        {...{ isFetchingTranslation, handleTranslationClick }}
      />
      {!showTransliteration && isChinese && (
        <PinyinButton {...{ handleTransliterationClick }} />
      )}
      {isLastAIMessage && (
        <SuggestionButton
          {...{ isFetchingSuggestion, handleSuggestionClick }}
        />
      )}
    </div>
  );
};

export default AIMessageButtons;
