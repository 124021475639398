import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useTeachersContext } from '../contexts/TeachersContext';
import { useUserContext } from '../contexts/UserContext';
import { EVENTS, STEPS_SELECTOR_CLASSES } from '../utils/constants';
import userAPI from '../api/user/userAPI';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';
import HeroAndAudioToggle from './HeroAndAudioToggle';
import SettingsButton from './SettingsButton';
import RestartChatButton from './RestartChatButton';
import HamburgerButton from './HamburgerButton';
import ChangeLanguageOrTeacherButton from './ChangeLanguageOrTeacherButton';
import LanguageSettingsTakeover, {
  LANGUAGE_SETTINGS_TAKEOVER,
} from './LanguageSettingsTakeover';

interface ChatHeaderProps {
  stopAllAudios: () => void;
  setPlayingStateMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  isDeletingChat: boolean;
  handleDeleteClick: () => void;
  isDisabled: boolean;
}

const ChatHeader = ({
  stopAllAudios,
  setPlayingStateMap,
  isDeletingChat,
  handleDeleteClick,
  isDisabled,
}: ChatHeaderProps) => {
  const { teachersAvatarURLMap, teachersMap } = useTeachersContext();
  const { user, settingsMap, updateUser } = useUserContext();
  const [showSettingsTakeover, setShowSettingsTakeover] = useState(false);
  const isTabletOrLarger = useIsTabletOrLarger();

  const selectedLanguageSettings = settingsMap[user.selectedLanguage]; //note consider setting this stuff in context
  const selectedTeacherId = selectedLanguageSettings.selectedTeacher;
  const teacher = teachersMap[selectedTeacherId];

  /**
   * Handle blur for settings takeover
   */
  useEffect(() => {
    if (!isTabletOrLarger) return;

    const handleBlur = (e: MouseEvent) => {
      if ((e.target as HTMLElement).id === LANGUAGE_SETTINGS_TAKEOVER) {
        setShowSettingsTakeover(false);
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);
    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isTabletOrLarger]);

  const toggleAutoPlay = async () => {
    if (user.isAutoPlayOn) {
      setPlayingStateMap({});
      stopAllAudios();
    }

    const updatedUser = await userAPI.updateUser({
      userEmail: user.email,
      isAutoPlayOn: !user.isAutoPlayOn,
    });

    updateUser(updatedUser);
  };

  const handleSettingsClick = () =>
    setShowSettingsTakeover(!showSettingsTakeover);

  const handleSettingsClose = () => setShowSettingsTakeover(false);

  return (
    <div
      className={classNames(
        `fixed flex items-center justify-between top-0 left-0 w-full bg-gray-100 h-20 z-20 ${STEPS_SELECTOR_CLASSES.CHAT_HEADER}`,
        showSettingsTakeover && 'z-30',
      )}
    >
      <HamburgerButton />
      <ChangeLanguageOrTeacherButton
        {...{ stopAllAudios, setPlayingStateMap }}
      />
      <HeroAndAudioToggle
        image={teachersAvatarURLMap[teacher.name]}
        name={teacher.name}
        toggleAutoPlay={toggleAutoPlay}
      />
      <RestartChatButton
        {...{ handleDeleteClick, isDisabled, isDeletingChat }}
      />
      <SettingsButton {...{ handleSettingsClick }} />
      {showSettingsTakeover && (
        <LanguageSettingsTakeover {...{ handleSettingsClose }} />
      )}
    </div>
  );
};

export default ChatHeader;
