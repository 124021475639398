import { ChangeEvent } from 'react';
import {
  LEVEL_SETTINGS_RADIO_NAMES,
  LEVEL_SETTINGS_RADIO_TITLES,
  NATIVE_LANG_FREQ,
} from '../utils/constants';
import RadioButtons from './RadioButtons';

interface NativeLangFrequencyRadioButtonsProps {
  nativeLanguageFrequency: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const NativeLangFrequencyRadioButtons = ({
  nativeLanguageFrequency,
  handleChange,
}: NativeLangFrequencyRadioButtonsProps) => {
  return (
    <RadioButtons
      title={LEVEL_SETTINGS_RADIO_TITLES.NATIVE_LANG_FREQ}
      options={Object.values(NATIVE_LANG_FREQ)}
      name={LEVEL_SETTINGS_RADIO_NAMES.NATIVE_LANG_FREQ}
      selectedOption={nativeLanguageFrequency}
      onChange={handleChange}
    />
  );
};

export default NativeLangFrequencyRadioButtons;
