import classNames from 'classnames';
import { TOPICS } from './ConversationTopics';

interface ConversationTopicButtonProps {
  text: string;
  handleTopicClick: (text: string) => void;
  isDisabled: boolean;
}

const ConversationTopicButton = ({
  text,
  handleTopicClick,
  isDisabled,
}: ConversationTopicButtonProps) => {
  const getTopic = (text: string) => {
    const letsTalkAbout = "Let's talk about";

    let topic = '';

    if (text === TOPICS.MY_DAY) {
      topic = `${letsTalkAbout} what I'm doing today`;
    } else if (text === TOPICS.YESTERDAY) {
      topic = `${letsTalkAbout} what I did yesterday`;
    } else if (text === TOPICS.TOMORROW) {
      topic = `${letsTalkAbout} what I'll do tomorrow`;
    } else {
      topic = `${letsTalkAbout} ${text.toLowerCase()}`;
    }

    return topic;
  };

  return (
    <button
      disabled={isDisabled}
      onClick={() => handleTopicClick(getTopic(text))}
      className={classNames(
        'bg-blue-600 hover:bg-blue-700 text-white font-bold p-3 rounded-xl text-xs md:text-sm',
        isDisabled && 'opacity-50 cursor-not-allowed',
      )}
    >
      <p className='whitespace-nowrap mb-1'>{text}</p>
    </button>
  );
};

export default ConversationTopicButton;
