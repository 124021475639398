import classNames from 'classnames';
import { TfiThought } from 'react-icons/tfi';
import CloseIcon from '@mui/icons-material/Close';
import { useWidgetContext } from '../contexts/WidgetContext';

interface TopicSuggestionsButtonProps {
  isDisabled: boolean;
  scrollToBottom: () => void;
}

const TopicSuggestionsButton = ({
  isDisabled,
  scrollToBottom,
}: TopicSuggestionsButtonProps) => {
  const {
    isTopicSuggestionsOpen,
    openTopicSuggestions,
    closeTopicSuggestions,
  } = useWidgetContext();

  const handleClick = () => {
    scrollToBottom();
    isTopicSuggestionsOpen ? closeTopicSuggestions() : openTopicSuggestions();
  };

  return (
    <button
      type='button'
      title={
        isTopicSuggestionsOpen
          ? 'Close topic suggestions'
          : 'Open topic suggestions'
      }
      disabled={isDisabled}
      onClick={handleClick}
      className={classNames(
        'bg-blue-700 hover:bg-blue-800 text-white p-3 rounded-xl w-10 h-10 flex items-center justify-center',
        isDisabled && 'opacity-50 cursor-not-allowed',
      )}
    >
      {isTopicSuggestionsOpen ? <CloseIcon /> : <TfiThought />}
    </button>
  );
};

export default TopicSuggestionsButton;
