import CorrectionButton from './CorrectionButton';

interface UserMessageButtonsProps {
  isFetchingCorrection: boolean;
  handleCorrectionClick: () => void;
}

const UserMessageButtons = ({
  isFetchingCorrection,
  handleCorrectionClick,
}: UserMessageButtonsProps) => {
  return (
    <div className='flex items-center gap-x-2'>
      <CorrectionButton {...{ isFetchingCorrection, handleCorrectionClick }} />
    </div>
  );
};

export default UserMessageButtons;
