import { Dispatch, SetStateAction, SyntheticEvent, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ChatIcon from '@mui/icons-material/Chat';
import SchoolIcon from '@mui/icons-material/School';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TheaterComedyIcon from '@mui/icons-material/TheaterComedy';
import AddCommentIcon from '@mui/icons-material/AddComment';
import { useTour } from '@reactour/tour';
import { FaChalkboardTeacher, FaDiscord } from 'react-icons/fa';
import Switch from '@mui/material/Switch';
import {
  DISCORD_SERVER_INVITE,
  EVENTS,
  PAGES,
  PATHS,
} from '../utils/constants';
import { useConfigContext } from '../contexts/ConfigContext';
import { useWidgetContext } from '../contexts/WidgetContext';
import { useUserContext } from '../contexts/UserContext';
import userAPI from '../api/user/userAPI';
import useIsDesktop from '../hooks/useIsDesktop';
import ShareButton from './ShareButton';
import toast from 'react-hot-toast';

const NAV_SLIDER = 'nav-slider';
const GIVE_FEEDBACK = 'Give feedback';
const TUTORIAL = 'Tutorial';
const JOIN_OUR_DISCORD = 'Join our Discord';

const NavSlider = ({
  setShowFeedbackTakeover,
}: {
  setShowFeedbackTakeover: Dispatch<SetStateAction<boolean>>;
}) => {
  const { isNavSliderOpen, toggleIsNavSliderOpen } = useWidgetContext();
  const { pathname } = useLocation();
  const { setIsOpen, setCurrentStep } = useTour();
  const {
    config: {
      featureFlags: { navigation: navigationFeatureFlags },
    },
  } = useConfigContext();
  const { user, updateUser } = useUserContext();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (!isNavSliderOpen) return;

    const handleBlur = (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest?.('#' + NAV_SLIDER)) {
        toggleIsNavSliderOpen();
      }
    };

    window.addEventListener(EVENTS.CLICK, handleBlur);

    return () => window.removeEventListener(EVENTS.CLICK, handleBlur);
  }, [isNavSliderOpen, toggleIsNavSliderOpen]);

  const handleNavSliderClick = (e: SyntheticEvent) => {
    e.stopPropagation();

    const target = (e.target as HTMLElement).closest('a');
    if (!target) return;

    if (target.title === GIVE_FEEDBACK) {
      setShowFeedbackTakeover(true);
    }

    if (target.title === TUTORIAL) {
      setCurrentStep(0);
      setIsOpen(true);
    }

    if (target.title === JOIN_OUR_DISCORD) {
      window.open(DISCORD_SERVER_INVITE, '_blank');
    }

    if (
      (target.title === PAGES.REVIEW && !navigationFeatureFlags.review) ||
      (target.title === PAGES.ROLE_PLAY && !navigationFeatureFlags.rolePlay) ||
      (target.title === PAGES.LESSONS && !navigationFeatureFlags.lessons)
    ) {
      return;
    }

    toggleIsNavSliderOpen();
  };

  const handleMicrophoneSideToggle = async () => {
    try {
      const updatedUser = await userAPI.updateUser({
        userEmail: user.email,
        isMicrophoneOnRight: !user.isMicrophoneOnRight,
      });

      updateUser(updatedUser);
    } catch (error) {
      toast.error('Error updating microphone side, please try again.');
    }
  };

  return (
    <nav
      id={NAV_SLIDER}
      className='fixed bottom-0 h-full z-50 w-2/3 overflow-y-auto bg-white px-5 py-3 lg:w-80 shadow-lg'
    >
      <button
        title='Close menu'
        onClick={() => toggleIsNavSliderOpen()}
        className='mb-4 pt-2'
      >
        <CloseRoundedIcon />
      </button>
      <div onClick={handleNavSliderClick}>
        <NavItem title={PAGES.CHAT} to={PATHS.CHAT} icon={<ChatIcon />} />
        {!isDesktop && <ShareButton />}
        <NavItem
          title={GIVE_FEEDBACK}
          to={pathname}
          icon={<AddCommentIcon />}
        />
        <NavItem
          title={JOIN_OUR_DISCORD}
          to={pathname}
          icon={<FaDiscord className='scale-125 ml-1' />}
        />
        <NavItem
          title={TUTORIAL}
          to={PATHS.CHAT}
          icon={<FaChalkboardTeacher className='scale-125 ml-1 text-black' />}
        />
        <NavItem
          title={PAGES.PROFILE}
          to={PATHS.PROFILE}
          icon={<AccountBoxIcon />}
        />
        <NavItem
          title={PAGES.REVIEW}
          to={PATHS.REVIEW}
          icon={<AssignmentIcon />}
          isFeatureEnabled={navigationFeatureFlags.review}
        />
        <NavItem
          title={PAGES.ROLE_PLAY}
          to={PATHS.ROLE_PLAY}
          icon={<TheaterComedyIcon />}
          isFeatureEnabled={navigationFeatureFlags.rolePlay}
        />
        <NavItem
          title={PAGES.LESSONS}
          to={PATHS.LESSONS}
          icon={<SchoolIcon />}
          isFeatureEnabled={navigationFeatureFlags.lessons}
        />
      </div>
      <div className='absolute left-6 bottom-8'>
        <Switch
          checked={user.isMicrophoneOnRight}
          onChange={handleMicrophoneSideToggle}
          inputProps={{ 'aria-label': 'controlled' }}
        />
        <span className='font-bold'>Microphone on Right</span>
      </div>
    </nav>
  );
};

interface NavItemProps {
  title: string;
  to: string;
  icon: JSX.Element;
  isFeatureEnabled?: boolean;
}

const NavItem = ({
  title,
  to,
  icon,
  isFeatureEnabled = true,
}: NavItemProps) => {
  return (
    <NavLink
      title={title}
      to={to}
      onClick={
        !isFeatureEnabled
          ? e => {
              e.preventDefault();
              toast(`${title} feature coming soon!`);
            }
          : undefined
      }
      className={({ isActive }) =>
        classNames(
          'flex items-center rounded-lg mb-1 px-4 py-2 hover:bg-blue-600 hover:text-white md:py-3 font-semibold',
          title === JOIN_OUR_DISCORD && 'hover:bg-[#5865F2]',
          isActive &&
            title !== GIVE_FEEDBACK &&
            title !== TUTORIAL &&
            title !== JOIN_OUR_DISCORD &&
            'text-white bg-blue-500',
          !isFeatureEnabled && 'opacity-50 cursor-not-allowed',
        )
      }
    >
      <div className='mr-2'>{icon}</div>
      <p>{title}</p>
    </NavLink>
  );
};

export default NavSlider;
