const Oops = ({ errorMessage }: { errorMessage?: string }) => (
  <div className='flex flex-col justify-center items-center h-screen w-screen p-4 bg-gray-100 font-bold'>
    <div className='bg-white flex flex-col justify-center items-center p-4 gap-4 rounded-lg'>
      <p>Oops! We had an error.</p>
      <p className='p-2 bg-gray-200 rounded-lg'>
        Error message: {errorMessage}
      </p>
      <p> Please refresh the page.</p>
      <button
        onClick={() => window.location.reload()}
        className='bg-blue-500 hover:bg-blue-600 w-40 p-4 rounded-lg shadow-lg text-white font-bold'
      >
        Refresh
      </button>
    </div>
  </div>
);

export default Oops;
