import { ChangeEvent } from 'react';
import {
  LEVEL,
  LEVEL_SETTINGS_RADIO_NAMES,
  LEVEL_SETTINGS_RADIO_TITLES,
} from '../utils/constants';
import RadioButtons from './RadioButtons';

interface LevelRadioButtonsProps {
  level: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const LevelRadioButtons = ({ level, handleChange }: LevelRadioButtonsProps) => {
  return (
    <RadioButtons
      title={LEVEL_SETTINGS_RADIO_TITLES.LEVEL}
      options={Object.values(LEVEL)}
      name={LEVEL_SETTINGS_RADIO_NAMES.LEVEL}
      selectedOption={level}
      onChange={handleChange}
    />
  );
};

export default LevelRadioButtons;
