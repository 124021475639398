import classNames from 'classnames';
import { SPINNER_CLASSES, SIZES } from '../utils/constants';

interface SpinnerProps {
  size?: SIZES;
  fullScreen?: boolean;
  white?: boolean;
}

const Spinner = ({
  size = SIZES.SMALL,
  fullScreen = false,
  white = false,
}: SpinnerProps) => {
  const spinner = (
    <div
      className={classNames(
        'animate-spin rounded-full border-x-transparent border-b-transparent',
        white ? 'border-t-white' : 'border-t-blue-500',
        {
          [SPINNER_CLASSES.SMALL]: size === SIZES.SMALL,
          [SPINNER_CLASSES.MEDIUM]: size === SIZES.MEDIUM,
          [SPINNER_CLASSES.LARGE]: size === SIZES.LARGE,
        },
      )}
    />
  );

  return fullScreen ? (
    <div
      className={classNames(
        'fixed inset-0 z-50 flex items-center justify-center',
      )}
    >
      {spinner}
    </div>
  ) : (
    spinner
  );
};

export default Spinner;
