import { useEffect, useRef } from 'react';
import { useConfigContext } from '../contexts/ConfigContext';

const updateMessage = `Please click "OK" to get the latest app update (page will refresh). 
    
If you wish to wait until later, click "Cancel" (please note that the app may not work as well without updates).`;

const useAppUpdates = () => {
  const { config } = useConfigContext();
  const previousReleaseRef = useRef(config.releaseDate);

  useEffect(() => {
    const hasReleaseDateDiff =
      previousReleaseRef.current !== config.releaseDate;

    if (!hasReleaseDateDiff) return;

    if (config.forceRefresh) {
      window.location.reload();
    } else {
      if (window.confirm(updateMessage)) {
        window.location.reload();
      } else {
        previousReleaseRef.current = config.releaseDate;
      }
    }
  }, [config.releaseDate, config.forceRefresh]);
};

export default useAppUpdates;
