import classNames from 'classnames';
import useIsTabletOrLarger from '../hooks/useIsTabletOrLarger';

interface FeatureProps {
  text: string;
  subtext?: string;
  image: string;
}

const Feature = ({ text, subtext, image }: FeatureProps) => {
  const isTabletOrLarger = useIsTabletOrLarger();

  return (
    <div
      className={classNames(
        'flex gap-5 items-center',
        !isTabletOrLarger && 'flex-col justify-center',
      )}
    >
      <div className='mb-2 flex-col flex gap-y-2'>
        <p className='font-bold text-xl'>{text}</p>
        {subtext && <p className='font-semibold text-lg'>{subtext}</p>}
      </div>
      <img src={image} alt='' className='rounded-lg w-80 justify-self-end' />
    </div>
  );
};

export default Feature;
