import { FormEvent, useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { Navigate, useNavigate } from 'react-router-dom';
import { FirebaseError } from 'firebase/app';
import auth from '../firebase';
import { DEV_TEAM_EMAIL, PATHS } from '../utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import LogoAndTitle from '../components/LogoAndTitle';
import toast from 'react-hot-toast';

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { currentUser } = useAuthContext();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setErrorMessage('');

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success(
        'Email with link to reset password sent. Please check your email for further instructions.',
        { duration: 10000 },
      );

      navigate(PATHS.LOGIN);
    } catch (error) {
      if (error instanceof FirebaseError) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage(
          `An unexpected error occurred. Please try again. If the problem persists, please contact the development team at ${DEV_TEAM_EMAIL}.`,
        );
      }
    }
  };

  if (currentUser) return <Navigate to={PATHS.CHAT} replace />;

  return (
    <div className='flex flex-col items-center justify-center min-h-screen bg-blue-500 text-white p-4'>
      <LogoAndTitle />
      <form
        onSubmit={handleSubmit}
        className='w-full max-w-xs space-y-6 bg-white text-black rounded-lg p-4'
      >
        <h2 className='text-center text-2xl font-bold'>Forgot password?</h2>
        {errorMessage && <p className='text-red-500 text-xs'>{errorMessage}</p>}
        <div>
          <label htmlFor='email' className='text-sm font-medium'>
            Email
          </label>
          <input
            type='email'
            id='email'
            value={email}
            onChange={e => setEmail(e.target.value)}
            className='w-full mt-1 p-2 text-sm border rounded-md bg-gray-200'
            required
          />
        </div>
        <button
          type='submit'
          className='w-full bg-blue-500 font-medium hover:bg-blue-600 text-white p-2 rounded-md transition duration-200'
        >
          Reset Password
        </button>
        <button
          type='button'
          onClick={() => navigate(PATHS.LOGIN)}
          className='w-full mt-2 hover:text-blue-500 font-medium transition duration-200'
        >
          Back to Sign In
        </button>
      </form>
    </div>
  );
};

export default ForgotPasswordPage;
