import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import App from './components/App';
import reportWebVitals from './reportWebVitals';
import Spinner from './components/Spinner';
import { SIZES } from './utils/constants';
import ErrorBoundary from './components/ErrorBoundary';
import Oops from './components/Oops';
import Providers from './components/Providers';
import AppInit from './components/AppInit';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={Oops}>
      <BrowserRouter future={{ v7_startTransition: true }}>
        <Suspense fallback={<Spinner fullScreen size={SIZES.LARGE} />}>
          <Providers>
            <AppInit>
              <App />
            </AppInit>
          </Providers>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
