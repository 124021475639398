import { useNavigate } from 'react-router-dom';
import { PATHS } from '../utils/constants';

const LogoAndTitle = () => {
  const navigate = useNavigate();

  return (
    <button
      onClick={() => navigate(PATHS.LANDING)}
      className='flex gap-x-2 items-center absolute top-5 left-5'
    >
      <img src='/favicon.ico' alt='' className='h-10 w-10' />
      <h1 className='text-2xl font-semibold'>Lingua Chat</h1>
    </button>
  );
};

export default LogoAndTitle;
