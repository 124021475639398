import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import topicSuggestionsAPI from '../api/topicSuggestionsAPI';
import ConversationTopicButton from './ConversationTopicButton';
import Spinner from './Spinner';
import toast from 'react-hot-toast';

interface ConversationTopicsProps {
  closeTopicSuggestions: () => void;
  handleTopicClick: (text: string) => void;
  isDisabled: boolean;
}

export const TOPICS = {
  MY_DAY: 'My day',
  YESTERDAY: 'Yesterday',
  TOMORROW: 'Tomorrow',
};

const ConversationTopics = ({
  closeTopicSuggestions,
  handleTopicClick,
  isDisabled,
}: ConversationTopicsProps) => {
  const [topicSuggestions, setTopicSuggestions] = useState<
    {
      text: string;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTopicSuggestions = async () => {
      try {
        const topicSuggestions =
          await topicSuggestionsAPI.fetchTopicSuggestions();

        setTopicSuggestions(topicSuggestions);
      } catch (error) {
        toast.error('Error fetching topic suggestions. Please try again.');
        closeTopicSuggestions();
      }

      setIsLoading(false);
    };

    fetchTopicSuggestions();
  }, [closeTopicSuggestions]);

  return (
    <div className='flex justify-center pt-2'>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className='overflow-x-auto'>
          <ConversationTopicsHeader {...{ closeTopicSuggestions }} />
          <div className='flex gap-x-2 mx-1 overflow-x-auto py-4'>
            {topicSuggestions.map(({ text }) => (
              <ConversationTopicButton
                {...{
                  text,
                  handleTopicClick,
                  isDisabled,
                  key: text,
                }}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

const ConversationTopicsHeader = ({
  closeTopicSuggestions,
}: {
  closeTopicSuggestions: () => void;
}) => {
  return (
    <div className='flex justify-between mx-2'>
      <p className='font-bold'>Let's talk about:</p>
      <button onClick={closeTopicSuggestions} className='mr-2'>
        <CloseIcon />
      </button>
    </div>
  );
};

export default ConversationTopics;
