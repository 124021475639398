import {
  Dispatch,
  SetStateAction,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import MicRecorder from 'mic-recorder-to-mp3';
import classNames from 'classnames';
import { useUserContext } from '../contexts/UserContext';
import { useConfigContext } from '../contexts/ConfigContext';
import useIsPWA from '../hooks/useIsPWA';
import MicroPhoneButton from './MicrophoneButton';
import SendTextButton from './SendTextButton';
import TopicSuggestionsButton from './TopicSuggestionsButton';

interface ChatInputFormProps {
  handleTextSubmit: (input: string) => void;
  handleMP3Submit: (mp3Blob: Blob) => void;
  stopAllAudios: () => void;
  setPlayingStateMap: Dispatch<SetStateAction<Record<string, boolean>>>;
  areInputButtonsDisabled: boolean;
  scrollToBottom: () => void;
}

const ChatInputForm = ({
  handleTextSubmit,
  handleMP3Submit,
  stopAllAudios,
  setPlayingStateMap,
  areInputButtonsDisabled,
  scrollToBottom,
}: ChatInputFormProps) => {
  const { config } = useConfigContext();
  const [input, setInput] = useState('');
  const [showSendTextButton, setShowSendTextButton] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const { isMicrophoneOnRight } = useUserContext().user;
  const isPWA = useIsPWA();

  const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);

  const startRecording = useCallback(() => {
    setIsRecording(true);

    recorder.start().catch((e: Error) => {
      console.log('Error starting recording ', e);
    });
  }, [recorder]);

  const stopRecording = useCallback(() => {
    setIsRecording(false);

    recorder
      .stop()
      .getMp3()
      .then(([_, blob]: [Buffer, Blob]) => {
        handleMP3Submit(blob);
      })
      .catch((e: Error) => {
        alert('We could not retrieve your message, please try again.');
        console.log('Error recording message: ', e);
      });
  }, [handleMP3Submit, recorder]);

  const stopRecordingWithoutSubmit = useCallback(() => {
    setIsRecording(false);
    recorder.stop();
  }, [recorder]);

  useEffect(() => {
    if (input.trim().length > 0) {
      if (isRecording) {
        stopRecordingWithoutSubmit();
      }
      setShowSendTextButton(true);

      setPlayingStateMap({});
      stopAllAudios();
    } else {
      setShowSendTextButton(false);
    }
  }, [
    input,
    isRecording,
    setPlayingStateMap,
    stopAllAudios,
    stopRecordingWithoutSubmit,
  ]);

  return (
    <form
      onSubmit={(e: SyntheticEvent) => {
        e.preventDefault();
        handleTextSubmit(input);
        setInput('');
      }}
      className={classNames(
        'flex justify-center px-4 items-center sticky left-0 bottom-0 w-full bg-white',
        isPWA ? 'p-8' : 'pb-6',
      )}
    >
      {config.featureFlags.features.topicSuggestions && isMicrophoneOnRight && (
        <div className='mr-2'>
          <TopicSuggestionsButton
            isDisabled={areInputButtonsDisabled}
            scrollToBottom={scrollToBottom}
          />
        </div>
      )}

      {!isMicrophoneOnRight && (
        <div className='mr-2'>
          {showSendTextButton ? (
            <SendTextButton {...{ areInputButtonsDisabled }} />
          ) : (
            <MicroPhoneButton
              {...{
                startRecording,
                stopRecording,
                areInputButtonsDisabled,
                stopAllAudios,
                isRecording,
              }}
            />
          )}
        </div>
      )}

      <input
        onFocus={scrollToBottom}
        autoFocus={false}
        autoComplete='off'
        value={input}
        placeholder='Enter message here'
        onChange={e => setInput(e.target.value)}
        className='w-5/6 lg:w-2/3 xl:w-1/2 2xl:w-1/3 p-3 placeholder-gray-600 border rounded-xl mr-2 focus:outline-blue-500'
      />

      {config.featureFlags.features.topicSuggestions &&
        !isMicrophoneOnRight && (
          <TopicSuggestionsButton
            isDisabled={areInputButtonsDisabled}
            scrollToBottom={scrollToBottom}
          />
        )}

      {isMicrophoneOnRight && (
        <>
          {showSendTextButton ? (
            <SendTextButton {...{ areInputButtonsDisabled }} />
          ) : (
            <MicroPhoneButton
              {...{
                startRecording,
                stopRecording,
                areInputButtonsDisabled,
                stopAllAudios,
                isRecording,
              }}
            />
          )}
        </>
      )}
    </form>
  );
};

export default ChatInputForm;
