import { ChangeEvent } from 'react';
import {
  CORRECTION_FREQ,
  LEVEL_SETTINGS_RADIO_NAMES,
  LEVEL_SETTINGS_RADIO_TITLES,
} from '../utils/constants';
import RadioButtons from './RadioButtons';

interface CorrectionFrequencyRadioButtonsProps {
  correctionFrequency: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const CorrectionFrequencyRadioButtons = ({
  correctionFrequency,
  handleChange,
}: CorrectionFrequencyRadioButtonsProps) => {
  return (
    <RadioButtons
      title={LEVEL_SETTINGS_RADIO_TITLES.CORRECTION_FREQ}
      options={Object.values(CORRECTION_FREQ)} //note consider removing "never" until prompt engineering actually works for that
      name={LEVEL_SETTINGS_RADIO_NAMES.CORRECTION_FREQ}
      selectedOption={correctionFrequency}
      onChange={handleChange}
    />
  );
};

export default CorrectionFrequencyRadioButtons;
