import { useContext, useState, createContext, PropsWithChildren } from 'react';

interface IWidgetContext {
  isNavSliderOpen: boolean;
  toggleIsNavSliderOpen: () => void;
  isTopicSuggestionsOpen: boolean;
  closeTopicSuggestions: () => void;
  openTopicSuggestions: () => void;
}

const WidgetContext = createContext<IWidgetContext>({
  isNavSliderOpen: false,
  toggleIsNavSliderOpen: () => {},
  isTopicSuggestionsOpen: false,
  closeTopicSuggestions: () => {},
  openTopicSuggestions: () => {},
});

export const useWidgetContext = () => useContext(WidgetContext);

export const WidgetProvider = ({ children }: PropsWithChildren) => {
  const [isNavSliderOpen, setIsNavSliderOpen] = useState(false);
  const [isTopicSuggestionsOpen, setIsTopicSuggestionsOpen] = useState(false);

  const toggleIsNavSliderOpen = () => {
    setIsNavSliderOpen(!isNavSliderOpen);
  };

  const closeTopicSuggestions = () => {
    setIsTopicSuggestionsOpen(false);
  };

  const openTopicSuggestions = () => {
    setIsTopicSuggestionsOpen(true);
  };

  return (
    <WidgetContext.Provider
      value={{
        isNavSliderOpen,
        toggleIsNavSliderOpen,
        isTopicSuggestionsOpen,
        closeTopicSuggestions,
        openTopicSuggestions,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
